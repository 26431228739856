import { NavigationBar } from '@/components/NavigationBar/NavigationBar'
import { Sidebar } from '@/components/Sidebar/Sidebar'
import { Content, Shell } from '@hivemq/ui-library'
import type { PropsWithChildren } from 'react'
import * as React from 'react'

export function Layout({ children }: PropsWithChildren) {
  return (
    <Shell.Root sidebarWidth="220px">
      <NavigationBar />
      <Sidebar />

      <Content.Root as="main">{children}</Content.Root>
    </Shell.Root>
  )
}
