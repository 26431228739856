// logic:
// 1. Look at ApprovalState (SUBMITTED | REJECTED | APPROVED | APPLIED)
//    - if SUBMITTED: updated_at === created_at so we can display <state>+<updated_at>+by+<created_by>
//    - if APPROVED: <updated_at>+by+<approved_by>
//    - if APPLIED -> We should have a HiveState -> Go to step 3
//      - if no HiveState present: there should be a disabled flag. Anyway assume that hive has been disabled
// 2. Look at RolloutState (BLOCKED | NOT_RECONCILED | RECONCILED | CRASHLOOPBACKOFF)
// 3. Look at HiveState (RUNNING | PENDING | ERROR | DELETING | LOCKED | UNKNOWN)
//
// States for display:
// - (gray) SUBMITTED if approval state = 'SUBMITTED'
// - (red) REJECTED if approval state = 'REJECTED'
// - (green) APPROVED if approval state = 'APPROVED'
// - if approval state = 'APPLIED':
//   - (gray) DISABLED if there's no hive state // TODO: once there's a disabled flag, handle it
//   - BLOCKED if rollout state = 'BLOCKED'
//   The next 5 might be too simplistic. Is it realistic to just look at hive state or can for example hive_state=DELETING have different RolloutStates and would it matter?
//   - (red) ERROR if hive state = ERROR or RolloutState = CRASHLOOPBACKOFF
//   - (orange=transition) DELETING if hive state = DELETING
//   - (orange) LOCKED (temporary state) if hive state = LOCKED
//   - (orange) UNKNOWN if hive state = UNKNOWN
//   - if rollout state = 'NOT_RECONCILED' - trying to apply a new version
//      - (green) if hive state is PENDING (broker is restarting or starting for the first time. Should be short)
//      - (green) UPDATING if rollout state = 'NOT_RECONCILED' - setting a new version. The old version is running, we're trying to update to a new version
//      - can hive state be ERROR or DELETING or LOCKED or UNKNOWN? -> yes, see cases above
//   - PENDING/DEPLOYING (broker is restarting or starting for the first time. Should be short) if hive state = PENDING - check if that's correct or too simplistic: maybe whilst pending we need to look at Rollout State?
//   - if rollout state is RUNNING:
//     - (green) RUNNING if rollout state = 'RECONCILED'
//     - (green) UPDATING if rollout state = 'NOT_RECONCILED' - setting a new version
//     - NOT_RECONCILED if rollout state = 'NOT_RECONCILED'

import type { LatestClusterRevision } from '@/generated/http-clients/v3ApiSchemas'

function isSubmitted({ revision }: LatestClusterRevision) {
  return revision.approval_state.state === 'SUBMITTED'
}

function isApproved({ revision }: LatestClusterRevision) {
  return revision.approval_state.state === 'APPROVED'
}

function isRejected({ revision }: LatestClusterRevision) {
  return revision.approval_state.state === 'REJECTED'
}

function isDisabled({ disabled }: LatestClusterRevision) {
  return !!disabled
}

function isBlocked({ revision }: LatestClusterRevision) {
  return revision.rollout?.state === 'BLOCKED'
}

function isDeleting({ revision }: LatestClusterRevision) {
  return revision.hive_state?.state === 'DELETING'
}

function isLocked({ revision }: LatestClusterRevision) {
  return revision.hive_state?.state === 'LOCKED'
}

function isUnknown({ revision }: LatestClusterRevision) {
  return revision.hive_state?.state === 'UNKNOWN'
}

function hasAProblem({ revision }: LatestClusterRevision) {
  return (
    revision.hive_state?.state === 'ERROR' ||
    revision.rollout?.state === 'CRASHLOOPBACKOFF'
  )
}

function isRunning({ revision }: LatestClusterRevision) {
  return (
    revision.hive_state?.state === 'RUNNING' &&
    revision.rollout?.state === 'RECONCILED'
  )
}

function isUpdating({ revision }: LatestClusterRevision) {
  return (
    revision.hive_state?.state === 'RUNNING' &&
    revision.rollout?.state === 'NOT_RECONCILED'
  )
}

// broker is restarting or starting for the first time. Should be short
function isRestarting({ revision }: LatestClusterRevision) {
  return (
    revision.hive_state?.state === 'PENDING' &&
    revision.rollout?.state === 'NOT_RECONCILED'
  )
}

/**
 * @namespace
 * ---
 * !important
 * Keep this as a namespace to prevent flood of imports in various places
 * We tried that in the customer facing frontend once and it was very confusing to have them imported individually.
 */
export default {
  isSubmitted,
  isApproved,
  isRejected,
  isDisabled,
  isDeleting,
  isLocked,
  isUnknown,
  hasAProblem,
  isBlocked,
  isRunning,
  isUpdating,
  isRestarting,
}
