import {
  type GetOrgsResponse,
  useGetOrgs,
} from '@/generated/http-clients/v2ApiComponents'
import { useGetTemplates } from '@/generated/http-clients/v3ApiComponents'
import type { TemplatesResponse } from '@/generated/http-clients/v3ApiSchemas'
import type React from 'react'
import { createContext, useContext } from 'react'

interface ConfigDataContextType {
  templatesData?: TemplatesResponse
  templatesIsLoading: boolean
  orgsData?: GetOrgsResponse
  orgsIsLoading: boolean
}

const ConfigDataContext = createContext<ConfigDataContextType | undefined>(
  undefined,
)

export const ConfigDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: templatesData, isLoading: templatesIsLoading } =
    useGetTemplates({
      queryParams: { page: 1, order: 'desc' }, // Use actual state if needed
    })

  const { data: orgsData, isLoading: orgsIsLoading } = useGetOrgs({})

  return (
    <ConfigDataContext.Provider
      value={{ templatesData, templatesIsLoading, orgsData, orgsIsLoading }}
    >
      {children}
    </ConfigDataContext.Provider>
  )
}

export const useConfigData = () => {
  const context = useContext(ConfigDataContext)
  if (!context) {
    throw new Error('useConfigData must be used within an ConfigDataProvider')
  }
  return context
}
