import type {
  HiveInfo,
  OrganizationHivesResponse,
  UserEmail,
  UserInfo,
} from '@/generated/http-clients/v2ApiSchemas'

/**
 * @RobinAtherton
 * I hate doing this but the data of the v2 request is formatted in a way that is not suitable for table
 * display so i flattened the object into something that is a little more usable.
 * @param data
 */
export const flattenHivesResponse = (
  data: OrganizationHivesResponse[] | undefined,
):
  | {
      org_id: string
      company_name?: string
      email: UserEmail | null
      user_info: UserInfo | null
      hive_info: HiveInfo | null
    }[]
  | undefined => {
  if (!data) {
    return
  }

  return data.flatMap(
    ({
      org_id,
      company_name,
      hives_info = [],
      users_info = [],
      email = [],
    }) => {
      const maxLength = Math.max(
        hives_info.length,
        users_info.length,
        email.length,
      )

      return Array.from({ length: maxLength }, (_, i) => ({
        org_id,
        company_name,
        hive_info: hives_info[i] || null,
        user_info: users_info[i] || null,
        email: email[i] || null,
      }))
    },
  )
}

export const flattenOrganizationHivesResponse = (
  data: OrganizationHivesResponse | undefined,
):
  | Array<{
      org_id: string
      company_name?: string
      email: UserEmail | null
      user_info: UserInfo | null
      hive_info: HiveInfo | null
    }>
  | undefined => {
  if (!data) {
    return undefined
  }

  const {
    org_id,
    company_name,
    hives_info = [],
    users_info = [],
    email = [],
  } = data

  const maxLength = Math.max(hives_info.length, users_info.length, email.length)

  return Array.from({ length: maxLength }, (_, i) => ({
    org_id,
    company_name,
    hive_info: hives_info[i] || null,
    user_info: users_info[i] || null,
    email: email[i] || null,
  }))
}
