import { useDebounce } from '@/helpers/useDebounce'
import {
  Button,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
} from '@chakra-ui-v2/react'
import { ChevronDown, LoaderCircle } from 'lucide-react'
import * as React from 'react'
import { FixedSizeList as List } from 'react-window'

type ValueTag = {
  value: string
  tag?: string
}

type Props = {
  data: ValueTag[]
  title: string
  loading?: boolean
  morePages?: boolean
  onLoadMore?: () => void
  onChange: (_value: string) => void
  defaultValue?: string
  width?: number | string
  isInvalid?: boolean
}

type RowProps = {
  index: number
  style: React.CSSProperties
}

function SearchMenu({
  data,
  title,
  morePages,
  loading,
  onLoadMore,
  onChange,
  defaultValue = '',
  width = '20rem',
  isInvalid = false,
}: Props) {
  const [filter, setFilter] = React.useState('')
  const [selected, setSelected] = React.useState('')

  React.useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  const debouncedFilter = useDebounce(filter, 300)
  const filteredData = React.useMemo(() => {
    return data.filter(
      (item) =>
        item.value.toLowerCase().includes(debouncedFilter.toLowerCase()) ||
        (!!item.tag &&
          item.tag.toLowerCase().includes(debouncedFilter.toLowerCase())),
    )
  }, [debouncedFilter, data])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilter(event.target.value)
  }

  function handleClick({ value }: ValueTag) {
    setSelected(value)
    onChange(value)
  }

  const Row = ({ index, style }: RowProps) => {
    const item = filteredData[index]
    return (
      <MenuItem
        style={style}
        onClick={() => handleClick(item)}
        tabIndex={-1}
        gap={2}
      >
        <Tag>
          <Text variant="M2">{item.value}</Text>
        </Tag>
        <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {item.tag}
        </Text>
      </MenuItem>
    )
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Icon as={ChevronDown} />}
        variant="outline"
        fontWeight={400}
        borderColor={
          isInvalid ? 'border.border-error-strong' : 'border.border-base'
        }
        backgroundColor="background.bg-base"
        borderWidth={isInvalid ? '2px' : '1px'}
        width="inherit"
        textAlign="start"
      >
        {selected || title}
      </MenuButton>
      <MenuList px={2} height="64" overflow="scroll" position="relative" pb={4}>
        <MenuItem position="sticky" top={-2} py={2}>
          <Input
            value={filter}
            onChange={handleChange}
            onClick={(event) => event.stopPropagation()}
          />
        </MenuItem>
        <List
          height={200}
          itemCount={filteredData.length}
          itemSize={35}
          width={width}
        >
          {Row}
        </List>
        {morePages ? (
          <MenuItem>
            {loading ? (
              <Icon as={LoaderCircle} />
            ) : (
              <Button type="button" onClick={onLoadMore}>
                Load More
              </Button>
            )}
          </MenuItem>
        ) : null}
      </MenuList>
    </Menu>
  )
}

export default React.memo(SearchMenu)
