declare global {
  export interface Window {
    hmqcStaticConfig: {
      VITE_ISSUER?: string
      VITE_CLIENT_ID?: string
      VITE_API_BASE_URL?: string
    }
  }
}

// @RobinAtherton - Get rid of this asap
export enum Status {
  Rest = 'rest',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}
