import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { LegacyHiveClusterCard } from '@/features/LegacyHives/components/LegacyHiveClusterCard'
import { flattenOrganizationHivesResponse } from '@/features/LegacyHives/util'
import { useFindHivesByOrgId } from '@/generated/http-clients/v2ApiComponents'
import { legacyHivesRootRoute } from '@/router/rootRoutes'
import { Skeleton } from '@chakra-ui-v2/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { createRoute } from '@tanstack/react-router'
import React, { useMemo } from 'react'
import { legacyHivesOrganizationDetailsRoute } from './LegacyHivesOrganizationDetails'

export const legacyHivesClusterDetailsRoute = createRoute({
  getParentRoute: () => legacyHivesRootRoute,
  path: '/orgs/$orgId/$clusterId',
  component: LegacyHivesClusterDetails,
})

export function LegacyHivesClusterDetails() {
  const pathParams = legacyHivesClusterDetailsRoute.useParams()

  const hivesRequest = useFindHivesByOrgId({
    pathParams: {
      orgId: pathParams.orgId,
    },
  })

  const flattenedData = useMemo(() => {
    if (hivesRequest.data) {
      return flattenOrganizationHivesResponse(hivesRequest.data)
    }
    return []
  }, [hivesRequest.data])

  const currentCluster = useMemo(() => {
    if (flattenedData) {
      return flattenedData.find(
        (cluster) => cluster.hive_info?.cluster_id === pathParams.clusterId,
      )
    }
  }, [flattenedData, pathParams.clusterId])

  const breadCrumbs = [
    { name: 'Legacy Hives', path: legacyHivesRootRoute.to },
    {
      name: pathParams.orgId,
      path: legacyHivesOrganizationDetailsRoute.to,
      pathParams: {
        orgId: pathParams.orgId,
      },
    },
    {
      name: pathParams.clusterId,
      path: legacyHivesClusterDetailsRoute.to,
    },
  ]

  // Render the cluster card
  return (
    <PageLayout
      title="Cluster Overview"
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
    >
      {hivesRequest.isLoading ? <Skeleton h="500px" w="100%" /> : null}
      {hivesRequest.isError || !hivesRequest.data ? (
        <ApiErrorState
          apiResource={hivesRequest as unknown as UseQueryResult}
          errorTitle="An error happened"
          errorDescription="We failed to load the data from the server for the Hive Details."
        />
      ) : null}
      {currentCluster ? (
        <LegacyHiveClusterCard cluster={currentCluster} />
      ) : null}
    </PageLayout>
  )
}
