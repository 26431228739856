import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import {
  ClusterConfigRequestProvider,
  useClusterConfigRequest,
} from '@/contexts/useConfigCreateRequest'
import { ConfigDataProvider } from '@/contexts/useConfigData'
import { KafkaExtensionPage } from '@/features/KafkaExtension/KafkaExtensionPage'
import { EditorWrapper } from '@/features/Templates/components/EditorWrapper'
import { usePostCluster } from '@/generated/http-clients/v3ApiComponents'
import type {
  ClusterHead,
  ClusterUpdate,
} from '@/generated/http-clients/v3ApiSchemas'
import type { DeepNonNullable } from '@/helpers/clusterTypes'
import { clustersRootRoute } from '@/router/rootRoutes'
import { isDevelop, isStaging } from '@/scripts/environments'
import {
  Box,
  Button,
  Code,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Tag,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui-v2/react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { CheckIcon, X } from 'lucide-react'
import * as React from 'react'
import { useRef, useState } from 'react'
import YAML from 'yaml'
import { NewClusterStep1System } from './NewClusterStep1System'

export const newClusterConfigRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: 'new',
  component: () => (
    <ClusterConfigRequestProvider>
      <NewClusterConfig />
    </ClusterConfigRequestProvider>
  ),
})

enum Steps {
  STEP1_SYSTEM = 0,
  STEP2_EXTENSIONS = 1,
  STEP3_OVERRIDES = 2,
  STEP4_REVIEW = 3,
}

export function NewClusterConfig() {
  const navigate = useNavigate()
  const toast = useToast()

  const createCluster = usePostCluster({})

  const stepOneRef = useRef<{ validateForm: () => boolean } | null>(null)

  const [configIsValid, setConfigIsValid] = useState(false)
  const [validationError, setValidationError] = useState('')

  const [currentStep, setCurrentStep] = useState(0)
  const [showKafkaConfig, setShowKafkaConfig] = useState(false)
  const [kafkaConfigHasBeenSaved, setKafkaConfigHasBeenSaved] = useState(false)

  const { request, onChange, errors } = useClusterConfigRequest()

  const debugModeAvailable = isDevelop || isStaging
  const [showDebug, setShowDebug] = useState(false)

  function handleChangeLogInput(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = event.target
    onChange('changelog', value)
  }

  const yamlRequest = React.useMemo(() => {
    try {
      const {
        system: systemData,
        override,
        kafkaExtension,
        templateId,
        deploymentLabel,
        organizationId,
        clusterName,
      } = request
      const system = YAML.parse(systemData)
        ?.system as DeepNonNullable<ClusterHead>['system']

      type OptionalCustomerSection = {
        customer?: {
          extensions?: {
            kafka?: string
          }
        }
      }

      let customerJson: undefined | OptionalCustomerSection = undefined
      if (kafkaExtension) {
        customerJson = {
          customer: {
            extensions: {
              kafka: YAML.parse(kafkaExtension),
            },
          },
        }
      }
      const customer = customerJson ? YAML.stringify(customerJson) : ''

      if (!system) {
        setConfigIsValid(false)
        setValidationError('system is empty')
        return ''
      }

      system.global = {
        ...system.global,
        name: clusterName,
        deployment_label: deploymentLabel.value,
      }
      const yaml =
        `${YAML.stringify({ system })}\n${override}\n${customer}\ntemplate_id: ${templateId}`.replace(
          /\n+/g,
          '\n',
        )

      if (!organizationId) {
        setConfigIsValid(false)
        setValidationError('organization ID is empty')
        return yaml
      }

      if (!templateId) {
        setConfigIsValid(false)
        setValidationError('template_id is empty')
        return yaml
      }

      setConfigIsValid(true)
      setValidationError('')

      return yaml
    } catch (error) {
      // console.error('Failed to parse YAML', error)
      // fail silently here
      // throw error
      setValidationError('YAML.stringify failed')
      setConfigIsValid(false)
    }
  }, [request])

  const kafkaConfigJson = React.useMemo(() => {
    if (!yamlRequest) {
      return
    }

    const jsonConfig: ClusterUpdate = {
      ...YAML.parse(yamlRequest),
    }
    return jsonConfig.customer?.extensions?.kafka
  }, [yamlRequest])

  function submit() {
    if (!yamlRequest) {
      // TODO: look at integrating a YAML validator to display errors. A schema would need to be provided by the backend via an API
      toast({
        title: 'This configuration cannot be submitted as the YAML is invalid.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    const jsonConfig: ClusterUpdate = {
      ...YAML.parse(yamlRequest),
    }

    createCluster
      .mutateAsync({
        pathParams: {
          orgId: request.organizationId,
        },
        body: jsonConfig,
      })
      .then(() => {
        toast({
          title: 'Created cluster config successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        return navigate({
          to: clustersRootRoute.to,
        })
      })
      .catch((error) => {
        console.error('error posting', error)
        toast({
          title: 'Failed to submit the new config',
          description: `${error.message}: ${error.stack._embedded.errors[0].message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }

  const steps = [
    {
      title: 'System',
      description: 'Base Hive Info',
      longerDescription: 'Provide the foundational details for the cluster.',
    },
    {
      title: 'Extensions',
      description: 'Set Up Extensions',
      longerDescription:
        'Set up customer extensions that will be available in this cluster.',
    },
    {
      title: 'Overrides',
      description: 'Optional Settings',
      longerDescription:
        'Customize the cluster by overriding template variables if needed.',
      optional: true,
    },
    {
      title: 'Final Details',
      description: 'Review & Submit',
      longerDescription:
        'Explain what this cluster is for, who it’s for, and any relevant details.',
    },
  ]

  function nextStep() {
    if (currentStep === Steps.STEP1_SYSTEM) {
      const formIsValid = stepOneRef.current?.validateForm()
      if (!formIsValid) {
        // some errors remain, can't move on yet
        return
      }
    }

    // check that all the extensions are valid
    if (currentStep === Steps.STEP2_EXTENSIONS) {
      if (showKafkaConfig && !kafkaConfigHasBeenSaved) {
        toast({
          title: 'Please save the Kafka configuration first',
          description:
            'You either need to remove the Kafka extension or to save it at the bottom of the form.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  function prevStep() {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  function addKafkaConfig() {
    setShowKafkaConfig(true)
  }

  function removeKafkaConfig() {
    onChange('kafkaExtension', '')
    setShowKafkaConfig(false)
    setKafkaConfigHasBeenSaved(false)
  }

  return (
    <PageLayout title="Create a cluster config">
      <VStack width="100%" gap={8}>
        <Stepper index={currentStep} width="100%">
          {steps.map((step) => (
            <Step key={`config-stepper-${step.title}`}>
              <StepIndicator
                sx={{
                  '[data-status=complete] &': {
                    color: 'text.text-on-color',
                  },
                }}
              >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <Box backgroundColor="border.border-base" width="100%">
                <StepSeparator />
              </Box>
            </Step>
          ))}
        </Stepper>

        <VStack w="100%" alignItems="start" gap={4}>
          <HStack
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
          >
            <HStack gap={4}>
              <Heading variant="h2">{steps[currentStep].title}</Heading>
              {Boolean(steps[currentStep].optional) && (
                <Tag variant="outline" fontSize="xs">
                  Optional
                </Tag>
              )}
            </HStack>
            <HStack gap={4}>
              <Button
                onClick={prevStep}
                isDisabled={currentStep === Steps.STEP1_SYSTEM}
                variant="outline"
                width="7rem"
              >
                Previous
              </Button>

              {currentStep === steps.length - 1 ? (
                <Button
                  variant="primary"
                  isDisabled={!request.changelog.length}
                  leftIcon={<Icon as={CheckIcon} ml={-1} />}
                  onClick={submit}
                  width="7rem"
                >
                  Submit
                </Button>
              ) : (
                <Button onClick={nextStep} variant="primary" width="7rem">
                  Continue
                </Button>
              )}
            </HStack>
          </HStack>
          <Text textColor="text.text-subtle">
            {steps[currentStep].longerDescription}
          </Text>
        </VStack>

        <Box width="100%" alignSelf="start">
          {currentStep === Steps.STEP1_SYSTEM && (
            <ConfigDataProvider>
              <NewClusterStep1System ref={stepOneRef} />
            </ConfigDataProvider>
          )}

          {currentStep === Steps.STEP2_EXTENSIONS && (
            <VStack data-testid="step-extensions" gap={6} alignItems="start">
              {showKafkaConfig ? (
                <Button onClick={removeKafkaConfig} width="12rem">
                  Remove Kafka Config
                </Button>
              ) : (
                <Button onClick={addKafkaConfig} width="12rem">
                  Add Kafka Config
                </Button>
              )}
              {showKafkaConfig && (
                <FormControl>
                  <KafkaExtensionPage
                    initialConfig={kafkaConfigJson}
                    onSubmit={(value) => {
                      try {
                        onChange('kafkaExtension', YAML.stringify(value))
                        toast({
                          title: 'Kafka Extension config added',
                          status: 'success',
                          isClosable: true,
                        })
                        setKafkaConfigHasBeenSaved(true)
                      } catch (error) {
                        console.error(
                          'Failed to validate the Kafka extension config',
                          error,
                        )
                        toast({
                          title: 'Failed to convert the Kafka extension config',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        })
                        setKafkaConfigHasBeenSaved(false)
                      }
                    }}
                  />
                </FormControl>
              )}
            </VStack>
          )}

          {currentStep === Steps.STEP3_OVERRIDES && (
            <VStack data-testid="step-overrides" gap={6}>
              <FormControl>
                <FormLabel w={36} display="inline-flex">
                  Overrides&nbsp;
                  <Text fontWeight={400} color="text.text-subtle">
                    (Optional)
                  </Text>
                </FormLabel>
                <FormHelperText mb={2}>
                  This section needs to be in valid YAML format, starting
                  with&nbsp;
                  <Code>override:</Code>.
                </FormHelperText>
                <EditorWrapper
                  yamlContent={request.override}
                  onChange={(value) => onChange('override', value)}
                />
              </FormControl>
            </VStack>
          )}

          {currentStep === Steps.STEP4_REVIEW && (
            <FormControl>
              <FormLabel w={36}>Description</FormLabel>
              <Textarea
                placeholder="Please leave a comment"
                name="changeLog"
                value={request.changelog}
                onChange={handleChangeLogInput}
              />
            </FormControl>
          )}
        </Box>

        {debugModeAvailable && !showDebug && (
          <Button
            size="sm"
            backgroundColor="yellow.600"
            _hover={{ backgroundColor: 'yellow.700' }}
            color="text.text-on-color"
            onClick={() => setShowDebug(true)}
          >
            Show debug
          </Button>
        )}

        {showDebug && (
          <VStack
            data-testid="debug"
            display="flex"
            justifyContent="flex-end"
            border=".0625rem solid"
            borderColor="yellow.600"
            borderRadius={4}
            alignItems="start"
            gap={0}
            my={4}
            w="100%"
            fontSize="sm"
          >
            <HStack
              p={2}
              width="100%"
              backgroundColor="yellow.600"
              justifyContent="space-between"
            >
              <VStack alignItems="start" gap={0}>
                <Text color="text.text-on-color" fontSize="sm" fontWeight="500">
                  Debugging tool
                </Text>
                <Text
                  color="text.text-on-color"
                  fontWeight={400}
                  fontStyle="italic"
                >
                  This YAML is a representation of the payload to be sent to the
                  backend. The real payload will be sent as JSON.
                </Text>
              </VStack>
              <HStack>
                <Tag colorScheme={configIsValid ? 'green' : 'red'}>
                  {configIsValid ? 'valid' : 'invalid'}
                </Tag>
                <IconButton
                  onClick={() => setShowDebug(false)}
                  aria-label="hide debug"
                  variant="ghost"
                  minWidth="24px"
                  height="24px"
                  border={0}
                  color="icon.icon-on-color"
                  _hover={{ backgroundColor: 'transparent' }}
                  icon={<Icon as={X} w={6} h={6} />}
                />
              </HStack>
            </HStack>
            {validationError.length && (
              <Box backgroundColor="background.bg-error" width="100%" p={2}>
                <Text>{validationError}</Text>
              </Box>
            )}
            <Box p={4}>
              <pre>organizationId: {request.organizationId}</pre>
              <pre>{yamlRequest}</pre>
            </Box>
          </VStack>
        )}
      </VStack>
    </PageLayout>
  )
}
