import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { DataTag } from '@/components/DataTag/DataTag'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { dynamicTemplateComparison } from '@/features/Templates/components/TemplateComparison'
import { useGetTemplates } from '@/generated/http-clients/v3ApiComponents'
import { timeAgo } from '@/helpers/timeFormatter'
import { templatesRootRoute } from '@/router/rootRoutes'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui-v2/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { Link, createRoute, useNavigate } from '@tanstack/react-router'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import * as React from 'react'

export const templateTableRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: '/',
  component: () => <TemplateTable />,
})

export function TemplateTable() {
  const nav = useNavigate()
  const [page, setPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const templates = useGetTemplates(
    {
      queryParams: { page, order: 'desc' },
    },
    {
      refetchInterval: 20000,
    },
  )

  // detect when the list of templates has been updated
  React.useEffect(() => {
    // when the templates data is update, check whether items
    // in selectedRows still exist in the template list.
    // Remove all the ones that do not exist anymore.
    const validIds =
      templates?.data?.templates?.map((item) => item.template_id) || []
    setSelectedRows((prevSelected) =>
      prevSelected.filter((id) => validIds.includes(id)),
    )
  }, [templates?.data?.templates])

  function handleNextPage() {
    if (templates.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  function handleCheckboxChange(id: string) {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
    } else if (selectedRows.length < 2) {
      setSelectedRows([...selectedRows, id])
    }
  }

  const handleCompare = () => {
    // Add your comparison logic here
    nav({
      to: dynamicTemplateComparison.to,
      params: { templateId0: selectedRows[0], templateId1: selectedRows[1] },
    })
  }

  return (
    <PageLayout
      title="Templates"
      action={
        <Flex justifyContent="flex-end" gap="4">
          {!templates.isLoading &&
          (templates.data?.templates || []).length > 1 ? (
            <Tooltip
              label={
                selectedRows.length !== 2 ? 'Select 2 revisions to compare' : ''
              }
            >
              <Button
                variant="outline"
                onClick={handleCompare}
                isDisabled={selectedRows.length !== 2}
              >
                Compare changes
              </Button>
            </Tooltip>
          ) : null}
          <Link to="/templates/create">
            <Button variant="primary" leftIcon={<Icon as={Plus} ml={-1} />}>
              New template
            </Button>
          </Link>
        </Flex>
      }
    >
      {templates.isLoading ? <Skeleton h="500px" w="100%" /> : null}
      {templates.isError || templates.data ? (
        <ApiErrorState
          apiResource={templates as unknown as UseQueryResult}
          errorTitle="An error happened"
          errorDescription="We failed to load the data from the server for the templates."
        />
      ) : null}
      {templates.isSuccess && templates.data !== null ? (
        <VStack width="100%">
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th width="32px" paddingRight={0} />
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Tags</Th>
              </Tr>
            </Thead>
            <Tbody>
              {templates.isLoading
                ? Array.from({ length: 5 }).map((_) => (
                    <Tr key={Math.random()}>
                      {Array.from({ length: 4 }).map((_) => (
                        <Td key={Math.random()}>
                          <Skeleton height="20px" />
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}

              {templates?.data?.templates?.map((template) => (
                <Tr key={template.template_id}>
                  <Td data-testid="checkbox" pr={0}>
                    <Checkbox
                      isChecked={selectedRows.includes(template.template_id)}
                      onChange={() =>
                        handleCheckboxChange(template.template_id)
                      }
                      isDisabled={
                        selectedRows.length === 2 &&
                        !selectedRows.includes(template.template_id)
                      }
                    />
                  </Td>
                  <Td data-testid="template-id">
                    <DataTag value={template.template_id} hasCopy={false}>
                      {template.template_id}
                    </DataTag>
                  </Td>
                  <Td data-testid="template-name">
                    <Link
                      to="/templates/$templateId"
                      params={{ templateId: template.template_id }}
                    >
                      <Text
                        variant="P2"
                        fontWeight={700}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        _hover={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {template.name}
                      </Text>
                    </Link>
                    <Text fontSize="sm" color="gray">
                      {template.created_at && (
                        <>
                          Created {timeAgo(template.created_at)} by&nbsp;
                          {template.created_by}
                        </>
                      )}
                    </Text>
                  </Td>
                  <Td data-testid="tags">
                    <HStack gap={1}>
                      {template.tags?.map((tag) => (
                        <DataTag
                          key={tag}
                          value={tag}
                          colorScheme="orange"
                          hasCopy={false}
                        >
                          {tag}
                        </DataTag>
                      ))}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={4}
            width="100%"
          >
            <Button onClick={handlePreviousPage} isDisabled={page === 1}>
              Previous
            </Button>
            <Box alignContent="center">
              Page {templates.data?.current_page} of&nbsp;
              {templates.data?.total_pages}
            </Box>
            <Button
              onClick={handleNextPage}
              isDisabled={!templates.data?.has_next_page}
            >
              Next
            </Button>
          </Box>
        </VStack>
      ) : null}
    </PageLayout>
  )
}
