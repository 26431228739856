import { Code, Text } from '@chakra-ui-v2/react'
import { merge } from 'lodash'

const GENERIC_KAFKA_UI_SCHEMA = {
  $titleHide: true,
  'kafka-configuration': {
    $titleHide: true,
    'kafka-clusters': {
      $titleHide: true,
      'kafka-cluster': {
        $titleHide: true,
        'bootstrap-servers': {
          $label: 'Bootstrap Servers',
          $placeholder: 'Comma separated list of Kafka bootstrap servers',
          $description: (
            <>
              Insert the list of bootstrap server URLs of the Kafka cluster you
              want to connect your HiveMQ broker to. You can add a single server
              URL in the format <Code>host:port</Code> or add multiple server
              URLs as a comma separated list in the format&nbsp;
              <Code>host:port,host:port,…</Code>
            </>
          ),
        },
        authentication: {
          $title: 'Authentication',
          $description:
            'Add credentials to authenticate your HiveMQ Cloud Cluster with your Kafka bootstrap servers.',
          plain: {
            $titleHide: true,
            $title: 'Plain',
            username: {
              $label: 'Username',
              $placeholder: 'Kafka Username',
            },
            password: {
              $label: 'Password',
              $fieldType: 'password',
              $placeholder: 'Kafka Password',
            },
          },
        },
      },
    },
    'mqtt-to-kafka-mappings': {
      $titleHide: true,
      'mqtt-to-kafka-mapping': {
        $title: 'HiveMQ to Kafka Mapping',
        $description: (
          <>
            Define the MQTT topic from which you want to forward messages to
            your Kafka cluster. This integration forwards any message published
            with this MQTT topic to the Kafka topic specified in the destination
            topic input field.
            <br />
            <Text fontWeight={900} display="inline">
              Limitations:
            </Text>
            &nbsp;
            <Text fontWeight={700} display="inline">
              This integration supports throughput up to 250 MB/sec. Messages
              are sent with QoS 0 by default.
            </Text>
          </>
        ),

        'kafka-topic': {
          $label: 'To Kafka Topic (destination)',
          $placeholder: 'to.kafka.1',
        },
        'mqtt-topic-filters': {
          $titleHide: true,
          'mqtt-topic-filter': {
            $label: 'From MQTT Topic (source)',
            $placeholder: 'from/mqtt/1',
          },
        },
      },
    },
    'kafka-to-mqtt-mappings': {
      $titleHide: true,
      'kafka-to-mqtt-mapping': {
        $title: 'Kafka to HiveMQ Mapping',
        $description:
          'Define the Kafka topic from which you want to forward messages to your MQTT clients. This integration publishes each new message from your specified source topic in Kafka to your HiveMQ broker using the specified MQTT topic from the destination topic input field.',
        'kafka-topics': {
          $titleHide: true,
          'kafka-topic': {
            $label: 'From Kafka Topic (source)',
            $placeholder: 'from.kafka.2',
          },
        },
        'mqtt-topics': {
          $titleHide: true,
          'mqtt-topic': {
            $label: 'To MQTT Topic (destination)',
            $placeholder: 'to/mqtt/2',
          },
        },
      },
    },
  },
}

export const GENERIC_KAFKA_XML = `
  <kafka-configuration>
    <kafka-clusters>
      <kafka-cluster>
        <id>generic-kafka</id>
        <bootstrap-servers></bootstrap-servers>
        <authentication>
          <plain>
            <username></username>
            <password></password>
          </plain>
        </authentication>
        <tls>
          <enabled>true</enabled>
        </tls>
      </kafka-cluster>
    </kafka-clusters>
    <mqtt-to-kafka-mappings>
      <mqtt-to-kafka-mapping>
        <id>generic-kafka__mqtt-to-kafka</id>
        <cluster-id>generic-kafka</cluster-id>
        <kafka-topic></kafka-topic>
        <mqtt-topic-filters>
          <mqtt-topic-filter></mqtt-topic-filter>
        </mqtt-topic-filters>
      </mqtt-to-kafka-mapping>
    </mqtt-to-kafka-mappings>
    <kafka-to-mqtt-mappings>
      <kafka-to-mqtt-mapping>
        <id>generic-kafka__kafka-to-mqtt</id>
        <cluster-id>generic-kafka</cluster-id>
        <kafka-topics>
          <kafka-topic></kafka-topic>
        </kafka-topics>
        <mqtt-topics>
          <mqtt-topic></mqtt-topic>
        </mqtt-topics>
      </kafka-to-mqtt-mapping>
    </kafka-to-mqtt-mappings>
  </kafka-configuration>
  `

function mappingStructure(
  isMqttToKafkaRequired: boolean,
  isKafkaToMqttRequired: boolean,
) {
  const bothRequired = !isMqttToKafkaRequired && !isKafkaToMqttRequired

  return {
    'kafka-configuration': {
      'mqtt-to-kafka-mappings': {
        'mqtt-to-kafka-mapping': {
          'kafka-topic': {
            $isRequired: bothRequired || isMqttToKafkaRequired,
          },
          'mqtt-topic-filters': {
            'mqtt-topic-filter': {
              $isRequired: bothRequired || isMqttToKafkaRequired,
            },
          },
        },
      },
      'kafka-to-mqtt-mappings': {
        'kafka-to-mqtt-mapping': {
          'kafka-topics': {
            'kafka-topic': {
              $isRequired: bothRequired || isKafkaToMqttRequired,
            },
          },
          'mqtt-topics': {
            'mqtt-topic': {
              $isRequired: bothRequired || isKafkaToMqttRequired,
            },
          },
        },
      },
    },
  }
}

function calculateUISchemaWithRequiredFields(
  // biome-ignore lint/suspicious/noExplicitAny: We cast some super dark magic here
  values: any,
  // biome-ignore lint/suspicious/noExplicitAny: We cast some super dark magic here
  UISchemaOverwrites: any,
) {
  const kafkaToMqttMapping =
    values['kafka-configuration']?.['kafka-to-mqtt-mappings']?.[
      'kafka-to-mqtt-mapping'
    ]

  const mqttToKafkaMapping =
    values['kafka-configuration']?.['mqtt-to-kafka-mappings']?.[
      'mqtt-to-kafka-mapping'
    ]

  const hasStartedFillingOutMqttToKafkaMapping =
    mqttToKafkaMapping?.['kafka-topic'] !== '' ||
    mqttToKafkaMapping?.['mqtt-topic-filters']?.['mqtt-topic-filter'] !== ''

  const hasStartedFillingOutKafkaToMqttMapping =
    kafkaToMqttMapping?.['kafka-topics']?.['kafka-topic'] !== '' ||
    kafkaToMqttMapping?.['mqtt-topics']?.['mqtt-topic'] !== ''

  return merge(
    {},
    GENERIC_KAFKA_UI_SCHEMA,
    mappingStructure(
      hasStartedFillingOutMqttToKafkaMapping,
      hasStartedFillingOutKafkaToMqttMapping,
    ),
    UISchemaOverwrites,
  )
}

// biome-ignore lint/suspicious/noExplicitAny: accepting any here
export function calculateUiSchemaGenericKafka(jsonValues: any) {
  return calculateUISchemaWithRequiredFields(jsonValues, {
    'kafka-configuration': {
      'kafka-clusters': {
        'kafka-cluster': {
          tls: {
            $titleHide: true,
          },
        },
      },
      'mqtt-to-kafka-mappings': {
        'mqtt-to-kafka-mapping': {
          $description: (
            <>
              Add a mapping to stream messages from your MQTT topics to your
              Kafka topic. You can use wildcards to match multiple MQTT topics.
              (<Code>+</Code> or <Code>#</Code>)
            </>
          ),
        },
      },
      'kafka-to-mqtt-mappings': {
        'kafka-to-mqtt-mapping': {
          $description: (
            <>
              You can use&nbsp;
              {/* biome-ignore lint/nursery/useConsistentCurlyBraces: curly braces needed to avoid KAFKA_TOPIC being considered a variable */}
              <Code>{'${KAFKA_TOPIC}'}</Code>,&nbsp;
              {/* biome-ignore lint/nursery/useConsistentCurlyBraces: curly braces needed to avoid KAFKA_KEY and KAFKA_VALUE being considered as variables */}
              <Code>{'${KAFKA_KEY}'}</Code>, or <Code>{'${KAFKA_VALUE}'}</Code>
              &nbsp; as variable part in the MQTT topic. This variable part will
              be replaced during runtime with the value from the Kafka record.
            </>
          ),
        },
      },
    },
  })
}
