import revisionStates from '@/features/ClusterConfigs/utils/revisionStates'
import type { LatestClusterRevision } from '@/generated/http-clients/v3ApiSchemas'
import { Box, Stack, Text, Tooltip } from '@chakra-ui-v2/react'
import {
  Ban,
  CircleAlert,
  CircleMinus,
  FileCheck,
  FilePlus,
  FileQuestion,
  FileX,
  LoaderCircle,
  Lock,
  RefreshCw,
  RotateCcw,
} from 'lucide-react'
import type { PropsWithChildren } from 'react'
import styles from './clusterConfigState.module.css'

/**
 * Fields needed in revision:
 * - revision (of type ClusterRevision)
 *   - approval_state.state
 *   - rollout.state
 *   - rollout.state_message
 *   - hive_state.state
 * - disabled
 */
type ClusterConfigStateProps = {
  revision: LatestClusterRevision
}

type Props = {
  hoverText: string
} & ClusterConfigStateProps['revision']

function HoverText({ revision, hoverText }: Props) {
  return (
    <Stack gap={0}>
      <Text color="white" fontWeight="bold">
        {hoverText}
      </Text>
      <Text color="gray" fontSize="xs">
        Approval state: {revision.approval_state.state}
      </Text>
      <Text color="gray" fontSize="xs">
        Rollout state: {revision.rollout?.state || 'undefined'}
      </Text>
      <Text color="gray" fontSize="xs">
        Hive state: {revision.hive_state?.state || 'undefined'}
      </Text>
      {revision.rollout?.state_message ? (
        <Text color="gray" fontSize="xs">
          State message: {revision.rollout?.state_message}
        </Text>
      ) : null}
    </Stack>
  )
}

export function ClusterConfigCombinedStates({
  /**
   * The revision from which we need to interpret states.
   */
  revision,
}: PropsWithChildren<ClusterConfigStateProps>) {
  function renderContent() {
    if (revisionStates.isDeleting(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Deleting..." />}>
          <RotateCcw size="1rem" color="blue" className={styles.rotatingCcw} />
        </Tooltip>
      )
    }

    if (revisionStates.isDisabled(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Disabled" />}>
          <CircleMinus size="1rem" color="gray" />
        </Tooltip>
      )
    }

    if (revisionStates.hasAProblem(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Has a problem" />}>
          <CircleAlert size="1rem" color="red" />
        </Tooltip>
      )
    }

    if (revisionStates.isBlocked(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Blocked" />}>
          <Ban size="1rem" color="red" />
        </Tooltip>
      )
    }

    if (revisionStates.isDeleting(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Deleting..." />}>
          <RotateCcw size="1rem" color="blue" className={styles.rotatingCcw} />
        </Tooltip>
      )
    }

    if (revisionStates.isLocked(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Locked" />}>
          <Lock size="1rem" color="red" />
        </Tooltip>
      )
    }

    if (revisionStates.isRunning(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Running" />}>
          <div className={styles.circleLoader}>
            <div className={styles.circle} />
            <div className={styles.expandingLine} />
          </div>
        </Tooltip>
      )
    }

    if (revisionStates.isSubmitted(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Submitted" />}>
          <FilePlus size="1rem" color="gray" />
        </Tooltip>
      )
    }

    if (revisionStates.isApproved(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Approved" />}>
          <FileCheck size="1rem" color="green" />
        </Tooltip>
      )
    }

    if (revisionStates.isRejected(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Rejected" />}>
          <FileX size="1rem" color="red" />
        </Tooltip>
      )
    }

    if (revisionStates.isUnknown(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Unknown" />}>
          <FileQuestion size="1rem" color="orange" />
        </Tooltip>
      )
    }

    if (revisionStates.isUpdating(revision)) {
      return (
        <Tooltip label={<HoverText {...revision} hoverText="Updating" />}>
          <div className={styles.circleLoader}>
            <LoaderCircle
              size="1rem"
              color="green"
              className={styles.rotatingCw}
            />
            <div className={styles.smallCircle} />
            <div className={styles.expandingLine} />
          </div>
        </Tooltip>
      )
    }

    if (revisionStates.isRestarting(revision)) {
      return (
        <Tooltip
          label={
            <HoverText
              {...revision}
              hoverText="Broker restarting or starting for the first time"
            />
          }
        >
          <div className={styles.circleLoader}>
            <RefreshCw size="1rem" color="blue" className={styles.rotatingCw} />
          </div>
        </Tooltip>
      )
    }

    // By default, display a warning
    return (
      <Tooltip label={<HoverText {...revision} hoverText="Unknown" />}>
        <CircleAlert size="1rem" color="orange" />
      </Tooltip>
    )
  }

  return <Box paddingY="0.25rem">{renderContent()}</Box>
}
