import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { ReactEditor } from '@/components/Editor/HmqcEditor'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { EditableSectionsGroup } from '@/features/ClusterConfigs/components/EditableSectionGroup/EditableSectionGroup'
import { RevisionState } from '@/features/ClusterConfigs/components/States/RevisionState'
import {
  useApplyRevision,
  useApproveRevision,
  useGetClusterRevisionById,
  useGetClusterRevisionRender,
  useRejectRevision,
} from '@/generated/http-clients/v3ApiComponents'
import { router } from '@/router'
import { isProduction } from '@/scripts/environments'
import {
  Box,
  Button,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui-v2/react'
import { useOktaAuth } from '@okta/okta-react'
import type { UseQueryResult } from '@tanstack/react-query'
import { Link, createRoute, useNavigate } from '@tanstack/react-router'
import capitalize from 'lodash/capitalize'
import { ChevronDown, FileText, Layers, PlusIcon } from 'lucide-react'
import * as React from 'react'
import YAML from 'yaml'
import { useHandleKeyDown } from '../../utils/handleKeyDown'
import { clusterConfigDraftRoute } from '../ClusterConfigDraft/ClusterConfigDraft'
import { clusterConfigTableRoute } from '../ClusterConfigTable/ClusterConfigTable'
import { clusterConfigsRootRoute } from '../ClusterConfigsPage/ClusterConfigsPage'

const editorOptions = {
  language: 'yaml',
  automaticLayout: true,
  scrollBeyondLastLine: false,
  tabSize: 4,
  insertSpaces: true,
}

export const clusterConfigDiffRoute = createRoute({
  getParentRoute: () => clusterConfigsRootRoute,
  path: '$revisionId',
  component: () => <ClusterRevisionDetails />,
})

export function ClusterRevisionDetails() {
  const pathParams = clusterConfigDiffRoute.useParams()
  const popoverTextareaRef = React.useRef(null)
  const approveButtonRef = React.useRef<HTMLButtonElement>(null)
  const applyButtonRef = React.useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()
  const toast = useToast()

  const revision = useGetClusterRevisionById({ pathParams })
  const renderedRevision = useGetClusterRevisionRender({ pathParams })

  const { oktaAuth } = useOktaAuth()

  const [isCreator, setIsCreator] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true

    oktaAuth.getUser().then((user) => {
      if (isMounted) {
        setIsCreator(user.email === revision.data?.rollout?.created_by)
      }
    })

    return () => {
      isMounted = false
    }
  }, [oktaAuth, revision.data?.rollout?.created_by])

  const apiCall = {
    approve: useApproveRevision(),
    reject: useRejectRevision(),
    apply: useApplyRevision(),
  }

  const [changedState, setChangedState] = React.useState({
    comment: '',
    readyToSubmit: false,
  })

  //   biome-ignore lint/suspicious/noExplicitAny: @RobinAtherton - Get rid of this in the future
  const [editableSectionsState, setEditableSectionsState] = React.useState<any>(
    {},
  )

  React.useEffect(() => {
    if (renderedRevision.isSuccess && renderedRevision.data) {
      const parsedYaml = YAML.parse(renderedRevision.data.yaml) // Parse the YAML string into a JS object

      // biome-ignore lint/complexity/noBannedTypes: @RobinAtherton need to use a generic type for dynamic use here
      const sectionsState: { [key: string]: {} } = {}

      // Iterate through the parsed YAML to dynamically populate the sections
      // biome-ignore lint/complexity/noForEach: @RobinAtherton - Isn't a complex operation so shouldnt lead to performance issues
      Object.keys(parsedYaml).forEach((key) => {
        sectionsState[key] = parsedYaml[key]
      })

      setEditableSectionsState(sectionsState)
    }
  }, [renderedRevision.data, renderedRevision.isSuccess])

  const updateComment = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const updatedComment = event.target.value
    setChangedState((previousState) => ({
      ...previousState,
      comment: updatedComment,
      readyToSubmit: updatedComment.trim().length > 0,
    }))
  }

  const noWrapStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }

  const stateColor = React.useMemo(() => {
    if (
      revision.data?.approval_state?.state === 'APPROVED' ||
      revision.data?.approval_state?.state === 'APPLIED'
    ) {
      return 'green'
    }
    if (revision.data?.approval_state?.state === 'REJECTED') {
      return 'red'
    }
    return 'gray'
  }, [revision])

  function performAction(action: 'reject' | 'approve' | 'apply') {
    apiCall[action]
      .mutateAsync({
        body: {
          comment: changedState.comment,
        },
        pathParams,
      })
      .then(() => {
        toast({
          title: `${capitalize(action)} revision successful`,
          status: 'success',
          duration: 3000,
        })
        return navigate({
          to: clusterConfigsRootRoute.to,
          params: pathParams,
        })
      })
      .catch((error) => {
        console.error('error posting', error)
        toast({
          title: `Failed to ${action} the revision`,
          status: 'error',
          duration: 5000,
        })
      })
  }

  const breadCrumbs = [
    { name: 'Hives & Configs', path: clusterConfigTableRoute.to },
    {
      name: pathParams.clusterId,
      path: router.buildLocation({
        to: clusterConfigsRootRoute.to,
        params: pathParams,
      }).href,
    },
    { name: pathParams.revisionId, path: '' },
  ]

  const isSubmitted = revision.data?.approval_state?.state === 'SUBMITTED'
  const by = `by ${
    isSubmitted
      ? revision.data?.rollout?.created_by
      : revision.data?.rollout?.approved_by
  }`

  return (
    <PageLayout
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
      title={<span style={noWrapStyle}>Revision #{pathParams.revisionId}</span>}
      description={
        <>
          {revision.isLoading && <Skeleton height="20px" />}

          {revision.isSuccess && revision.data ? (
            <HStack color="text.text-subtle">
              <Text m={0}>State:</Text>
              <Tag gap={1} colorScheme={stateColor}>
                <RevisionState approvalState={revision.data.approval_state} />
                <span>{revision.data.approval_state.state}</span>
              </Tag>
              <Text fontSize="sm">{by}</Text>
            </HStack>
          ) : null}
        </>
      }
      action={
        <HStack gap={4}>
          <Tooltip label="Based on this revision">
            <Link
              to={clusterConfigDraftRoute.to}
              params={{
                ...pathParams,
                baseRevisionId: pathParams.revisionId,
              }}
            >
              <Button
                variant="outline"
                leftIcon={<Icon as={PlusIcon} ml={-1} />}
              >
                Create new revision
              </Button>
            </Link>
          </Tooltip>
          {revision.data?.approval_state?.state === 'SUBMITTED' && (
            <Popover
              placement="bottom-end"
              initialFocusRef={popoverTextareaRef}
            >
              {isCreator && isProduction ? (
                <Text fontSize="sm" maxW="200px" color="text.text-subtle">
                  Revisions created by you need to be reviewed by someone else
                </Text>
              ) : (
                <>
                  <PopoverTrigger>
                    <Button
                      variant="primary"
                      rightIcon={<Icon as={ChevronDown} mr={-1} />}
                    >
                      Review submission
                    </Button>
                  </PopoverTrigger>
                </>
              )}

              <PopoverContent>
                <PopoverHeader fontWeight="semibold" border={0}>
                  Review config submission
                  <PopoverCloseButton top={2} />
                </PopoverHeader>
                <PopoverBody>
                  <Textarea
                    placeholder="Leave a comment"
                    value={changedState.comment}
                    onChange={updateComment}
                    onKeyDown={useHandleKeyDown(approveButtonRef)}
                    ref={popoverTextareaRef}
                  />
                </PopoverBody>
                <PopoverFooter
                  display="flex"
                  justifyContent="end"
                  border={0}
                  gap={2}
                >
                  <Button
                    variant="dangerous"
                    onClick={() => performAction('reject')}
                    isDisabled={!changedState.readyToSubmit}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="positive"
                    onClick={() => performAction('approve')}
                    ref={approveButtonRef}
                    isDisabled={!changedState.readyToSubmit}
                  >
                    Approve
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          )}
          {revision.data?.approval_state?.state === 'APPROVED' && (
            <Popover
              placement="bottom-end"
              initialFocusRef={popoverTextareaRef}
            >
              <PopoverTrigger>
                <Button
                  variant="primary"
                  rightIcon={<Icon as={ChevronDown} mr={-1} />}
                >
                  Apply revision
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold" border={0}>
                  Apply config revision
                  <PopoverCloseButton top={2} />
                </PopoverHeader>
                <PopoverBody>
                  <VStack alignItems="left">
                    <Text color="text.text-subtle" variant="P3">
                      Are you sure you want to apply this revision?
                    </Text>
                    <Textarea
                      placeholder="Leave a comment"
                      value={changedState.comment}
                      onChange={updateComment}
                      onKeyDown={useHandleKeyDown(applyButtonRef)}
                      ref={popoverTextareaRef}
                    />
                  </VStack>
                </PopoverBody>
                <PopoverFooter
                  display="flex"
                  justifyContent="end"
                  border={0}
                  gap={2}
                >
                  <Button
                    variant="positive"
                    onClick={() => performAction('apply')}
                    isDisabled={!changedState.readyToSubmit}
                    ref={applyButtonRef}
                  >
                    Apply
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          )}
        </HStack>
      }
    >
      <Box w="100%">
        <>
          {renderedRevision.isLoading ? <Skeleton h="400px" /> : null}

          {renderedRevision.isError || !renderedRevision.data ? (
            <ApiErrorState
              apiResource={renderedRevision as unknown as UseQueryResult}
              errorTitle="An error happened"
              errorDescription="We failed to load the data from the server for the rendered revision"
            />
          ) : null}

          {renderedRevision.isSuccess && renderedRevision.data !== null ? (
            <Tabs>
              <TabList>
                <Tab>
                  <Icon as={Layers} mr={2} />
                  Layers
                </Tab>
                <Tab>
                  <Icon as={FileText} mr={2} />
                  Raw
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <EditableSectionsGroup
                    sections={Object.keys(editableSectionsState).map((key) => ({
                      id: key,
                      title: key,
                      value: YAML.stringify(editableSectionsState[key]),
                      isReadOnly: true,
                    }))}
                    editorOptions={editorOptions}
                    onChange={(updatedData) =>
                      setEditableSectionsState(
                        //   biome-ignore lint/complexity/noBannedTypes: @RobinAtherton - Get rid of this in the future
                        (prev: { [key: string]: {} }) => ({
                          ...prev,
                          ...updatedData,
                        }),
                      )
                    }
                  />
                </TabPanel>
                <TabPanel>
                  <ReactEditor
                    language="yaml"
                    value={renderedRevision.data?.yaml}
                    options={{
                      readOnly: true,
                      language: 'yaml',
                      automaticLayout: true,
                      scrollBeyondLastLine: false,
                      tabSize: 4,
                      insertSpaces: true,
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : null}
        </>
      </Box>
    </PageLayout>
  )
}
