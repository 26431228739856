import {
  DiffEditor,
  type DiffEditorProps,
  type Monaco,
  type MonacoDiffEditor,
} from '@monaco-editor/react'
import * as React from 'react'

export function ReactDiffEditor(props: DiffEditorProps) {
  const editorRef = React.useRef<MonacoDiffEditor | null>(null)
  const monacoRef = React.useRef<Monaco | null>(null)
  const [height, setHeight] = React.useState(0)

  function handleEditorDidMount(editor: MonacoDiffEditor, monaco: Monaco) {
    editorRef.current = editor
    monacoRef.current = monaco
    updateEditorHeight()
  }

  function updateEditorHeight() {
    if (editorRef.current && monacoRef.current) {
      const editor = editorRef.current.getModifiedEditor()
      const lineHeight = editor.getOption(
        monacoRef.current.editor.EditorOption.lineHeight,
      )

      const modifiedLineCount = editor.getModel()?.getLineCount() || 1
      const newHeight = lineHeight * modifiedLineCount + 20 // Add padding

      setHeight(newHeight)
      editorRef.current.layout()
    }
  }

  React.useEffect(() => {
    updateEditorHeight()
  })

  return (
    <div
      style={{
        border: '2px solid black',
        borderRadius: '10px',
        padding: '0.5rem',
      }}
    >
      <DiffEditor
        height={height || '50px'}
        onMount={handleEditorDidMount}
        original={props.original}
        modified={props.modified}
        {...props}
        options={{
          scrollbar: {
            vertical: 'hidden',
            horizontal: 'hidden',
            alwaysConsumeMouseWheel: false,
          },
          minimap: { enabled: false },
          overviewRulerLanes: 0,
          folding: false,
          scrollBeyondLastColumn: 0,
          automaticLayout: true,
          scrollBeyondLastLine: false,
          fontSize: 16,
          renderIndicators: true,
          readOnly: true,
        }}
      />
    </div>
  )
}
