import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { DataTag } from '@/components/DataTag/DataTag'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { legacyHivesClusterDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesClusterDetails'
import { legacyHivesOrganizationDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesOrganizationDetails'
import { flattenHivesResponse } from '@/features/LegacyHives/util'
import { useFindHives } from '@/generated/http-clients/v2ApiComponents'
import { legacyHivesRootRoute } from '@/router/rootRoutes'
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui-v2/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { createRoute, useNavigate } from '@tanstack/react-router'
import debounce from 'lodash/debounce'
import {
  ArrowLeft,
  ArrowLeftToLine,
  ArrowRight,
  ArrowRightToLine,
  Search,
} from 'lucide-react'
import type React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const legacyHivesRoute = createRoute({
  getParentRoute: () => legacyHivesRootRoute,
  path: '/',
  component: LegacyHivesTable,
})

const FIRST_PAGE = 1

function LegacyHivesTable() {
  const [page, setPage] = useState(1) // Current page
  const [filter, setFilter] = useState('') // Filter text
  const [searchQuery, setSearchQuery] = useState('') // search query based on the filter text
  const hivesRequest = useFindHives({
    queryParams: { ...(searchQuery ? { searchQuery } : { page }) },
  })
  const navigate = useNavigate()
  const toast = useToast()

  const debouncedSetSearchQuery = useCallback(
    debounce((value: string) => {
      setSearchQuery(value)
    }, 300),
    [],
  )

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFilter(value) // Update input value immediately
    debouncedSetSearchQuery(value) // Debounce the search query update
  }

  // clean up the debounced function when the component unmounts
  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel()
    }
  }, [debouncedSetSearchQuery])

  function handleNextPage() {
    if (hivesRequest.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > FIRST_PAGE) {
      setPage((prev) => prev - 1)
    }
  }

  function goToFirstPage() {
    setPage(FIRST_PAGE)
  }

  function goToLastPage() {
    setPage(hivesRequest.data?.total_pages || page)
  }

  function routeToClusterDetails(orgId: string, clusterId: string | undefined) {
    if (clusterId) {
      navigate({
        to: legacyHivesClusterDetailsRoute.to,
        params: {
          orgId,
          clusterId,
        },
      })
    } else {
      toast({
        title: 'Cluster currently not accessible.',
        status: 'warning',
      })
    }
  }

  function routeToOrganizationDetails(orgId: string) {
    navigate({
      to: legacyHivesOrganizationDetailsRoute.to,
      params: {
        orgId,
      },
    })
  }

  /**
   * Memo to hold the flattened data to make it easier to iterate over in the table.
   */
  const legacyHivesTableData = useMemo(() => {
    return flattenHivesResponse(hivesRequest.data?.hives)
  }, [hivesRequest])

  const filteredHives = legacyHivesTableData

  /**
   *
   * "RUNNING" | "PENDING" | "ERROR" | "DELETING" | "LOCKED"
   * @param status the input status
   */
  function getColorForStatus(status: string | undefined) {
    if (status === undefined) {
      return 'orange.200'
    }
    if (status === 'RUNNING') {
      return 'green.200'
    }
    if (status === 'PENDING') {
      return 'orange.200'
    }
    if (status === 'ERROR') {
      return 'red.400'
    }
    if (status === 'DELETING') {
      return 'red.300'
    }
    if (status === 'LOCKED') {
      return 'blue.300'
    }
  }

  const skeletonWidths = [120, 250, 100, 100, 80, 200, 100]

  return (
    <PageLayout title="Legacy Hives">
      <VStack mb={4} width="50%" gap={2} alignItems="start">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={Search} />
          </InputLeftElement>
          <Input
            placeholder="Search for organization ID or name, cluster ID, or email..."
            value={filter}
            onChange={handleFilterChange}
            backgroundColor="background.bg-base"
          />
        </InputGroup>
        <Text mt={0} color="text.text-subtle" fontSize="xs" pl={4}>
          Organization and cluster info can be partial, but email must be a full
          address (e.g., user@example.com)
        </Text>
      </VStack>
      <Box
        px={3}
        py={4}
        width="100%"
        border="1px solid"
        borderColor="border.border-base"
        borderRadius={12}
        backgroundColor="background.bg-base"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Cluster ID</Th>
              <Th>Org ID</Th>
              <Th>Company Name </Th>
              <Th>Plan</Th>
              <Th>Apiary</Th>
              <Th>Email</Th>
            </Tr>
          </Thead>
          <Tbody>
            {hivesRequest.isLoading &&
              Array.from({ length: 10 }, (_, rowIndex) => (
                <Tr
                  key={`skeleton-${
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    rowIndex
                  }`}
                >
                  {skeletonWidths.map((width, columnIndex) => (
                    <Td
                      key={`cell-${rowIndex}-${
                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                        columnIndex
                      }`}
                    >
                      <Skeleton w={`${width}px`} h="20px" />
                    </Td>
                  ))}
                </Tr>
              ))}

            {hivesRequest.isError && (
              <Tr>
                <Td colSpan={7}>
                  <ApiErrorState
                    apiResource={hivesRequest as unknown as UseQueryResult}
                    errorTitle="An error happened"
                    errorDescription="We failed to load the data from the server for the templates."
                  />
                </Td>
              </Tr>
            )}

            {hivesRequest.isSuccess &&
              (filteredHives?.length === 0 ? (
                <Tr>
                  <Td colSpan={7}>
                    <Text>No hives found for the organization.</Text>
                  </Td>
                </Tr>
              ) : (
                !!legacyHivesTableData &&
                !!filteredHives &&
                filteredHives.map((cluster, index) => (
                  <Tr
                    key={`${cluster.org_id}-${index}`}
                    _hover={{ backgroundColor: 'gray.100' }}
                  >
                    <Td data-testid="cluster-status">
                      <HStack>
                        <DataTag
                          backgroundColor={getColorForStatus(
                            cluster.hive_info?.hive_status,
                          )}
                          value={cluster.hive_info?.hive_status}
                          hasCopy={false}
                        >
                          {cluster.hive_info?.hive_status}
                        </DataTag>
                      </HStack>
                    </Td>

                    <Td data-testid="cluster-id">
                      {cluster?.hive_info?.cluster_id ? (
                        <DataTag
                          onClickBody={() =>
                            routeToClusterDetails(
                              cluster.org_id,
                              cluster.hive_info?.cluster_id,
                            )
                          }
                          hasCopy
                          value={cluster.hive_info?.cluster_id}
                          maxWidthPx={200}
                        >
                          {cluster.hive_info?.cluster_id}
                        </DataTag>
                      ) : (
                        <DataTag>N / A</DataTag>
                      )}
                    </Td>

                    <Td data-testid="org-id">
                      <DataTag
                        hasCopy
                        onClickBody={() =>
                          routeToOrganizationDetails(cluster.org_id)
                        }
                        value={cluster.org_id}
                      >
                        {cluster.org_id}
                      </DataTag>
                    </Td>

                    <Td data-testid="company-name">
                      <DataTag hasCopy value={cluster.company_name}>
                        {cluster.company_name}
                      </DataTag>
                    </Td>

                    <Td data-testid="cluster-plan">
                      <Text fontSize="md"> {cluster.hive_info?.plan} </Text>
                    </Td>

                    <Td data-testid="apiary">{cluster.hive_info?.apiary}</Td>

                    <Td data-testid="email">{cluster.email?.email_address}</Td>
                  </Tr>
                ))
              ))}
          </Tbody>
        </Table>

        {hivesRequest.isSuccess && (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            mt={4}
          >
            <HStack gap={8}>
              <HStack gap={4} data-testid="previous-pages">
                <IconButton
                  onClick={goToFirstPage}
                  isDisabled={page === 1}
                  aria-label="First page"
                  variant="ghost"
                  minWidth="24px"
                  height="24px"
                  border={0}
                  icon={<Icon as={ArrowLeftToLine} w={6} h={6} />}
                />
                <IconButton
                  onClick={handlePreviousPage}
                  isDisabled={page === 1}
                  aria-label="Previous page"
                  variant="ghost"
                  minWidth="24px"
                  height="24px"
                  border={0}
                  icon={<Icon as={ArrowLeft} w={6} h={6} />}
                />
              </HStack>

              <HStack gap={4} data-testid="next-pages">
                <IconButton
                  onClick={handleNextPage}
                  isDisabled={!hivesRequest.data?.has_next_page}
                  aria-label="Next page"
                  variant="ghost"
                  minWidth="24px"
                  height="24px"
                  border={0}
                  icon={<Icon as={ArrowRight} w={6} h={6} />}
                />
                <IconButton
                  onClick={goToLastPage}
                  isDisabled={page >= (hivesRequest.data?.total_pages || page)}
                  aria-label="Last page"
                  variant="ghost"
                  minWidth="24px"
                  height="24px"
                  border={0}
                  icon={<Icon as={ArrowRightToLine} w={6} h={6} />}
                />
              </HStack>

              <Box data-testid="current-page">
                Page <strong>{hivesRequest.data?.current_page}</strong> of&nbsp;
                <strong>{hivesRequest.data?.total_pages}</strong>
              </Box>
            </HStack>
          </Box>
        )}
      </Box>
    </PageLayout>
  )
}
