import {
  type DeploymentLabelOption,
  deploymentLabels,
} from '@/helpers/deploymentLabels'
import type React from 'react'
import { createContext, useContext, useState } from 'react'
import YAML from 'yaml'

type ClusterConfigRequest = {
  system: string
  override: string
  kafkaExtension: string
  templateId: string
  deploymentLabel: DeploymentLabelOption
  organizationId: string
  clusterName: string
  changelog: string
}

interface ClusterConfigRequestContextType {
  request: ClusterConfigRequest
  onChange: <K extends keyof ClusterConfigRequest>(
    name: K,
    value: ClusterConfigRequest[K],
  ) => void
  errors: Record<string, string>
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

const ClusterConfigRequestContext = createContext<
  ClusterConfigRequestContextType | undefined
>(undefined)

export const ClusterConfigRequestProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [request, setRequest] = useState<ClusterConfigRequest>({
    system: YAML.stringify({ system: { global: {} } }),
    override: '',
    kafkaExtension: '',
    templateId: '',
    deploymentLabel: deploymentLabels[0],
    organizationId: '',
    clusterName: '',
    changelog: '',
  })

  const [errors, setErrors] = useState<Record<string, string>>({})

  function onChange<K extends keyof ClusterConfigRequest>(
    name: K,
    value: ClusterConfigRequest[K],
  ) {
    setRequest((prevState) => ({ ...prevState, [name]: value }))

    if (['organizationId', 'templateId'].includes(name) && !!errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
    }
  }

  return (
    <ClusterConfigRequestContext.Provider
      value={{ request, onChange, errors, setErrors }}
    >
      {children}
    </ClusterConfigRequestContext.Provider>
  )
}

export const useClusterConfigRequest = () => {
  const context = useContext(ClusterConfigRequestContext)
  if (!context) {
    throw new Error('useRequest must be used within a RequestProvider')
  }
  return context
}
