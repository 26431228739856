import { DataInput } from '@/components/DataInput/DataInput'
import { DataTag } from '@/components/DataTag/DataTag'
import { SectionCard } from '@/components/SectionCard/SectionCard'
import { ContextMenu } from '@/features/ClusterConfigs/components/ContextMenu/ContextMenu'
import { useDeleteCluster } from '@/generated/http-clients/v2ApiComponents'
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui-v2/react'
import { useNavigate, useParams } from '@tanstack/react-router'
import React from 'react'
import { legacyHivesOrganizationDetailsRoute } from '../pages/LegacyHivesOrganizationDetails'

type LegacyHiveClusterCardProps = {
  //biome-ignore lint/suspicious/noExplicitAny:
  cluster: any
  index?: number
}

export function LegacyHiveClusterCard({
  cluster,
  index,
}: LegacyHiveClusterCardProps) {
  const deleteRequest = useDeleteCluster()
  const navigate = useNavigate()
  const toast = useToast()
  const pathParams = useParams({ strict: false })
  const { isOpen, onOpen, onClose } = useDisclosure()

  function deleteCluster() {
    if (pathParams.orgId) {
      deleteRequest
        .mutateAsync({
          pathParams: {
            orgId: pathParams.orgId,
            clusterId: cluster.clusterId,
          },
        })
        .then(() => {
          toast({
            title: 'Request to delete hive was sent successfully',
            status: 'success',
            duration: 2000,
          })
          if (pathParams.orgId) {
            return navigate({
              to: legacyHivesOrganizationDetailsRoute.to,
              params: {
                orgId: pathParams.orgId,
              },
            })
          }
        })
        .catch((error) => {
          console.error('error posting', error)
          toast({
            title: 'Failed to delete the hive',
            status: 'error',
            duration: 5000,
          })
        })
    }
  }

  return (
    <>
      <SectionCard
        mt={4}
        key={cluster.hive_info.cluster_id + Math.random()}
        title={
          index !== null && index !== undefined
            ? `Cluster #${index + 1}`
            : 'Cluster'
        }
        actions={
          <Box display="flex" justifyContent="flex-end">
            <ContextMenu
              items={[{ itemName: 'Delete Hive', action: onOpen }]}
            />
          </Box>
        }
      >
        <DataInput
          mt={2}
          label="Cluster ID"
          value={cluster.hive_info.cluster_id}
        >
          {cluster.hive_info.cluster_id}
        </DataInput>
        <DataInput
          mt={2}
          label="Stripe ID"
          value={cluster.user_info?.stripe_id}
        >
          {cluster.user_info?.stripe_id}
        </DataInput>
        <DataInput mt={2} label="User ID" value={cluster.user_info?.user_id}>
          {cluster.user_info?.user_id}
        </DataInput>
        {/*  @RobinAtherton - TODO this is a backend typing error but lets just ignore it for now, */}
        {/*  this should be of type ModifiedHiveInfo but it is of Type HiveInfo*/}
        <DataInput mt={2} label="Plan" value={cluster.hive_info.plan}>
          {cluster.hive_info.plan}
        </DataInput>
        {/*  TODO need to replace this with some icon in the top of the sectioncard somewhere*/}
        <DataInput mt={2} label="Status" value={cluster.hive_info.hive_status}>
          {cluster.hive_info.hive_status}
        </DataInput>
        <DataInput mt={2} label="Apiary" value={cluster.hive_info.apiary}>
          {cluster.hive_info.apiary}
        </DataInput>
        <DataInput
          mt={2}
          label="Authentication Method"
          value={cluster.hive_info.authentication_method}
        >
          {cluster.hive_info.authentication_method}
        </DataInput>
        <Text mt={2} fontWeight={500} fontSize={16}>
          Broker Features
        </Text>
        {cluster.hive_info.broker_features.length > 0 &&
          cluster.hive_info.broker_features?.map((feature: string) => (
            <DataTag key={feature} value={feature}>
              {feature}
            </DataTag>
          ))}
        {cluster.hive_info.broker_features.length === 0 && (
          <Tag mt={2} backgroundColor="red.200">
            N / A
          </Tag>
        )}
      </SectionCard>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete this Hive?</ModalHeader>
          <ModalBody>
            <Text>This will delete this hive and all of its revisions.</Text>
            <HStack mt={4} pb={3} justifyContent="end">
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="dangerous" onClick={deleteCluster}>
                Delete Hive
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
