import { useOktaAuth } from '@okta/okta-react'
import * as React from 'react'

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

export enum ErrorMessage {
  NotFound = 'Not Found',
}

type Options = {
  path: string
  method: HTTPMethod
  signal?: AbortSignal
  version?: ApiVersion
  params?: Record<string | number, unknown>
  payload?: Record<string | number, unknown>
  contentType?: string
}

export function useFetchCall() {
  const { authState } = useOktaAuth()

  async function fetchCall<T = any>({
    path,
    method,
    signal,
    contentType,
    payload,
    version = ApiVersion.V2,
    params = {},
  }: Options): Promise<T | undefined> {
    if (!authState?.accessToken?.accessToken) {
      throw new Error('User not yet Authenticated')
    }

    const queryParams = new URLSearchParams(
      params as unknown as URLSearchParams,
    ).toString()

    const baseUrl =
      import.meta.env.VITE_API_BASE_URL ||
      window?.hmqcStaticConfig?.VITE_API_BASE_URL
    const url = `${baseUrl}/api/${version}/${path}?${queryParams}`

    const headers = {
      Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      'Content-Type': contentType || 'application/json',
    }

    // eslint-disable-next-line no-useless-catch
    try {
      const options: Record<string, unknown> = {
        mode: 'cors',
        method,
        signal,
        headers,
        params,
        body: JSON.stringify(payload),
      }

      const response = await fetch(url, options)
      if (
        Number(response?.headers.get('content-length')) === 0 ||
        response.status === 402
      ) {
        return
      }

      const result = await response.json()

      if (response.status >= 400) {
        throw result
      }

      return result as unknown as T
    } catch (error) {
      // throw new Error((error as Error).message)
      throw error
    }
  }

  return { fetchCall }
}
