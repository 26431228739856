import type { DeploymentLabel } from '@/generated/http-clients/v3ApiSchemas'
import { type Environment, getEnvironment } from '@/scripts/environments'

const commonDeploymentLabels: DeploymentLabel[] = [
  'apiary-s01-stg-eu-central-1',
  'apiary-a02-staging-us-west-2',
]

const deploymentLabelsPerEnv: { [E in Environment]: DeploymentLabel[] } = {
  staging: commonDeploymentLabels,
  development: commonDeploymentLabels,
  test: commonDeploymentLabels,
  production: [
    'apiary-a01-prod-eu-central-1',
    'apiary-a02-prod-us-west-2',
    'apiary-a03-prod-eu-central-1',
    'shared-poc01-gcp-prod-europe-west3',
  ],
}

export type DeploymentLabelOption = {
  label: DeploymentLabel
  value: DeploymentLabel
}

export const deploymentLabels: DeploymentLabelOption[] = (
  deploymentLabelsPerEnv[getEnvironment()] ?? commonDeploymentLabels
).map((label) => ({ label, value: label }))
