import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui-v2/react'
import { Ellipsis } from 'lucide-react'
import React from 'react'

type ContextMenuProps = {
  items: { itemName: string; action: () => void }[]
}

const iconSize = 5

export function ContextMenu({ items }: ContextMenuProps) {
  return (
    <Menu>
      <MenuButton
        height={iconSize}
        alignItems="baseline"
        _hover={{ cursor: 'pointer', color: 'blue' }}
      >
        <Icon boxSize={iconSize} as={Ellipsis} />
      </MenuButton>
      {items ? (
        <MenuList>
          {items.map((item) => (
            <MenuItem key={item.itemName} onClick={item.action}>
              {item.itemName}
            </MenuItem>
          ))}
        </MenuList>
      ) : null}
    </Menu>
  )
}
