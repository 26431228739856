import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui-v2/react'
import { Link } from '@tanstack/react-router'
import React from 'react'

type Props = {
  paths: { name: string; path: string }[]
}

export function BreadCrumbs({ paths }: Props) {
  const lastIndex = paths.length - 1

  return (
    <Breadcrumb fontWeight="medium" fontSize="md" color="blue.600">
      {paths.map(({ name, path }, index) => (
        <BreadcrumbItem key={name} isCurrentPage={index === lastIndex}>
          <BreadcrumbLink as={Link} to={path}>
            {name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}
