/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.11
 */
import * as reactQuery from "@tanstack/react-query";
import { useV3ApiContext, V3ApiContext } from "./v3ApiContext";
import type * as Fetcher from "./v3ApiFetcher";
import { v3ApiFetch } from "./v3ApiFetcher";
import type * as Schemas from "./v3ApiSchemas";

export type PostClusterPathParams = {
  orgId: string;
};

export type PostClusterError = Fetcher.ErrorWrapper<undefined>;

export type PostClusterVariables = {
  body: Schemas.ClusterUpdate;
  pathParams: PostClusterPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchPostCluster = (
  variables: PostClusterVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ClusterCreationRevisionResponse,
    PostClusterError,
    Schemas.ClusterUpdate,
    {},
    {},
    PostClusterPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters",
    method: "post",
    ...variables,
    signal,
  });

export const usePostCluster = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ClusterCreationRevisionResponse,
      PostClusterError,
      PostClusterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ClusterCreationRevisionResponse,
    PostClusterError,
    PostClusterVariables
  >({
    mutationFn: (variables: PostClusterVariables) =>
      fetchPostCluster({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetClusterPathParams = {
  orgId: string;
  clusterId: string;
};

export type GetClusterError = Fetcher.ErrorWrapper<undefined>;

export type GetClusterVariables = {
  pathParams: GetClusterPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetCluster = (
  variables: GetClusterVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ClusterHead,
    GetClusterError,
    undefined,
    {},
    {},
    GetClusterPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetCluster = <TData = Schemas.ClusterHead,>(
  variables: GetClusterVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClusterHead, GetClusterError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<Schemas.ClusterHead, GetClusterError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}",
      operationId: "getCluster",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCluster({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteClusterPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
  /**
   * Cluster id to delete
   */
  clusterId: string;
};

export type DeleteClusterError = Fetcher.ErrorWrapper<undefined>;

export type DeleteClusterVariables = {
  pathParams: DeleteClusterPathParams;
} & V3ApiContext["fetcherOptions"];

/**
 * delete a Cluster
 */
export const fetchDeleteCluster = (
  variables: DeleteClusterVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    undefined,
    DeleteClusterError,
    undefined,
    {},
    {},
    DeleteClusterPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * delete a Cluster
 */
export const useDeleteCluster = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteClusterError,
      DeleteClusterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteClusterError,
    DeleteClusterVariables
  >({
    mutationFn: (variables: DeleteClusterVariables) =>
      fetchDeleteCluster({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutClusterPathParams = {
  orgId: string;
  clusterId: string;
};

export type PutClusterError = Fetcher.ErrorWrapper<undefined>;

export type PutClusterVariables = {
  body: Schemas.ClusterUpdate;
  pathParams: PutClusterPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchPutCluster = (
  variables: PutClusterVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.RevisionResponse,
    PutClusterError,
    Schemas.ClusterUpdate,
    {},
    {},
    PutClusterPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}",
    method: "put",
    ...variables,
    signal,
  });

export const usePutCluster = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RevisionResponse,
      PutClusterError,
      PutClusterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.RevisionResponse,
    PutClusterError,
    PutClusterVariables
  >({
    mutationFn: (variables: PutClusterVariables) =>
      fetchPutCluster({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSupportedExtensionsError = Fetcher.ErrorWrapper<undefined>;

export type GetSupportedExtensionsVariables = V3ApiContext["fetcherOptions"];

/**
 * List of available cluster extensions
 */
export const fetchGetSupportedExtensions = (
  variables: GetSupportedExtensionsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.SupportedExtensionsList,
    GetSupportedExtensionsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/v3/clusters/extensions",
    method: "get",
    ...variables,
    signal,
  });

/**
 * List of available cluster extensions
 */
export const useGetSupportedExtensions = <
  TData = Schemas.SupportedExtensionsList,
>(
  variables: GetSupportedExtensionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.SupportedExtensionsList,
      GetSupportedExtensionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.SupportedExtensionsList,
    GetSupportedExtensionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/clusters/extensions",
      operationId: "getSupportedExtensions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSupportedExtensions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateHiveCommentPathParams = {
  orgId: string;
  clusterId: string;
};

export type UpdateHiveCommentError = Fetcher.ErrorWrapper<undefined>;

export type UpdateHiveCommentVariables = {
  body: Schemas.HiveCommentUpdate;
  pathParams: UpdateHiveCommentPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchUpdateHiveComment = (
  variables: UpdateHiveCommentVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    undefined,
    UpdateHiveCommentError,
    Schemas.HiveCommentUpdate,
    {},
    {},
    UpdateHiveCommentPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/comment",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateHiveComment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateHiveCommentError,
      UpdateHiveCommentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateHiveCommentError,
    UpdateHiveCommentVariables
  >({
    mutationFn: (variables: UpdateHiveCommentVariables) =>
      fetchUpdateHiveComment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetClusterRevisionByIdPathParams = {
  orgId: string;
  clusterId: string;
  revisionId: string;
};

export type GetClusterRevisionByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetClusterRevisionByIdVariables = {
  pathParams: GetClusterRevisionByIdPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetClusterRevisionById = (
  variables: GetClusterRevisionByIdVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ClusterRevision,
    GetClusterRevisionByIdError,
    undefined,
    {},
    {},
    GetClusterRevisionByIdPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetClusterRevisionById = <TData = Schemas.ClusterRevision,>(
  variables: GetClusterRevisionByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ClusterRevision,
      GetClusterRevisionByIdError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.ClusterRevision,
    GetClusterRevisionByIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}",
      operationId: "getClusterRevisionById",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetClusterRevisionById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetClusterAppliedRevisionPathParams = {
  orgId: string;
  clusterId: string;
};

export type GetClusterAppliedRevisionError = Fetcher.ErrorWrapper<undefined>;

export type GetClusterAppliedRevisionVariables = {
  pathParams: GetClusterAppliedRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetClusterAppliedRevision = (
  variables: GetClusterAppliedRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ClusterRevision,
    GetClusterAppliedRevisionError,
    undefined,
    {},
    {},
    GetClusterAppliedRevisionPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/applied",
    method: "get",
    ...variables,
    signal,
  });

export const useGetClusterAppliedRevision = <TData = Schemas.ClusterRevision,>(
  variables: GetClusterAppliedRevisionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ClusterRevision,
      GetClusterAppliedRevisionError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.ClusterRevision,
    GetClusterAppliedRevisionError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/applied",
      operationId: "getClusterAppliedRevision",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetClusterAppliedRevision(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetClusterRevisionsPathParams = {
  orgId: string;
  clusterId: string;
};

export type GetClusterRevisionsError = Fetcher.ErrorWrapper<undefined>;

export type GetClusterRevisionsResponse = Schemas.Revision[];

export type GetClusterRevisionsVariables = {
  pathParams: GetClusterRevisionsPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetClusterRevisions = (
  variables: GetClusterRevisionsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    GetClusterRevisionsResponse,
    GetClusterRevisionsError,
    undefined,
    {},
    {},
    GetClusterRevisionsPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions",
    method: "get",
    ...variables,
    signal,
  });

export const useGetClusterRevisions = <TData = GetClusterRevisionsResponse,>(
  variables: GetClusterRevisionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetClusterRevisionsResponse,
      GetClusterRevisionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    GetClusterRevisionsResponse,
    GetClusterRevisionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions",
      operationId: "getClusterRevisions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetClusterRevisions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetClusterRevisionRenderPathParams = {
  orgId: string;
  clusterId: string;
  revisionId: string;
};

export type GetClusterRevisionRenderError = Fetcher.ErrorWrapper<undefined>;

export type GetClusterRevisionRenderVariables = {
  pathParams: GetClusterRevisionRenderPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetClusterRevisionRender = (
  variables: GetClusterRevisionRenderVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.Template,
    GetClusterRevisionRenderError,
    undefined,
    {},
    {},
    GetClusterRevisionRenderPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revision/{revisionId}/render",
    method: "get",
    ...variables,
    signal,
  });

export const useGetClusterRevisionRender = <TData = Schemas.Template,>(
  variables: GetClusterRevisionRenderVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Template,
      GetClusterRevisionRenderError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.Template,
    GetClusterRevisionRenderError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revision/{revisionId}/render",
      operationId: "getClusterRevisionRender",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetClusterRevisionRender(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ApproveRevisionPathParams = {
  orgId: string;
  clusterId: string;
  revisionId: string;
};

export type ApproveRevisionError = Fetcher.ErrorWrapper<undefined>;

export type ApproveRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: ApproveRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchApproveRevision = (
  variables: ApproveRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    ApproveRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    ApproveRevisionPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}/approve",
    method: "put",
    ...variables,
    signal,
  });

export const useApproveRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      ApproveRevisionError,
      ApproveRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    ApproveRevisionError,
    ApproveRevisionVariables
  >({
    mutationFn: (variables: ApproveRevisionVariables) =>
      fetchApproveRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RejectRevisionPathParams = {
  orgId: string;
  clusterId: string;
  revisionId: string;
};

export type RejectRevisionError = Fetcher.ErrorWrapper<undefined>;

export type RejectRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: RejectRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchRejectRevision = (
  variables: RejectRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    RejectRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    RejectRevisionPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}/reject",
    method: "put",
    ...variables,
    signal,
  });

export const useRejectRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      RejectRevisionError,
      RejectRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    RejectRevisionError,
    RejectRevisionVariables
  >({
    mutationFn: (variables: RejectRevisionVariables) =>
      fetchRejectRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApplyRevisionPathParams = {
  orgId: string;
  clusterId: string;
  revisionId: string;
};

export type ApplyRevisionError = Fetcher.ErrorWrapper<undefined>;

export type ApplyRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: ApplyRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchApplyRevision = (
  variables: ApplyRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    ApplyRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    ApplyRevisionPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}/apply",
    method: "put",
    ...variables,
    signal,
  });

export const useApplyRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      ApplyRevisionError,
      ApplyRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    ApplyRevisionError,
    ApplyRevisionVariables
  >({
    mutationFn: (variables: ApplyRevisionVariables) =>
      fetchApplyRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RollbackRevisionPathParams = {
  orgId: string;
  clusterId: string;
  revisionId: string;
};

export type RollbackRevisionError = Fetcher.ErrorWrapper<undefined>;

export type RollbackRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: RollbackRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchRollbackRevision = (
  variables: RollbackRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    RollbackRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    RollbackRevisionPathParams
  >({
    url: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}/rollback",
    method: "put",
    ...variables,
    signal,
  });

export const useRollbackRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      RollbackRevisionError,
      RollbackRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    RollbackRevisionError,
    RollbackRevisionVariables
  >({
    mutationFn: (variables: RollbackRevisionVariables) =>
      fetchRollbackRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllClustersRevisionsQueryParams = {
  /**
   * The page number for pagination.
   *
   * @format int32
   * @default 1
   */
  page?: number;
  orgId?: string;
  /**
   * @example submitted
   */
  approvalState?: "submitted" | "rejected" | "approved" | "applied";
  /**
   * @example asc
   * @default desc
   */
  order?: "asc" | "desc";
  hiveId?: string;
  /**
   * @example Starter,Dedicated
   */
  tags?: string;
};

export type GetAllClustersRevisionsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllClustersRevisionsVariables = {
  queryParams?: GetAllClustersRevisionsQueryParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetAllClustersRevisions = (
  variables: GetAllClustersRevisionsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ClustersRevisionsResponse,
    GetAllClustersRevisionsError,
    undefined,
    {},
    GetAllClustersRevisionsQueryParams,
    {}
  >({ url: "/api/v3/clusters", method: "get", ...variables, signal });

export const useGetAllClustersRevisions = <
  TData = Schemas.ClustersRevisionsResponse,
>(
  variables: GetAllClustersRevisionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ClustersRevisionsResponse,
      GetAllClustersRevisionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.ClustersRevisionsResponse,
    GetAllClustersRevisionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/clusters",
      operationId: "getAllClustersRevisions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllClustersRevisions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetProductCatalogError = Fetcher.ErrorWrapper<undefined>;

export type GetProductCatalogVariables = V3ApiContext["fetcherOptions"];

export const fetchGetProductCatalog = (
  variables: GetProductCatalogVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ProductCatalogHead,
    GetProductCatalogError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/v3/product/catalog", method: "get", ...variables, signal });

export const useGetProductCatalog = <TData = Schemas.ProductCatalogHead,>(
  variables: GetProductCatalogVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProductCatalogHead,
      GetProductCatalogError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProductCatalogHead,
    GetProductCatalogError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/product/catalog",
      operationId: "getProductCatalog",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetProductCatalog({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutProductCatalogError = Fetcher.ErrorWrapper<undefined>;

export type PutProductCatalogVariables = {
  body?: Schemas.ProductCatalogUpdate;
} & V3ApiContext["fetcherOptions"];

export const fetchPutProductCatalog = (
  variables: PutProductCatalogVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.RevisionResponse,
    PutProductCatalogError,
    Schemas.ProductCatalogUpdate,
    {},
    {},
    {}
  >({ url: "/api/v3/product/catalog", method: "put", ...variables, signal });

export const usePutProductCatalog = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RevisionResponse,
      PutProductCatalogError,
      PutProductCatalogVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.RevisionResponse,
    PutProductCatalogError,
    PutProductCatalogVariables
  >({
    mutationFn: (variables: PutProductCatalogVariables) =>
      fetchPutProductCatalog({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApproveCatalogRevisionPathParams = {
  revisionId: string;
};

export type ApproveCatalogRevisionError = Fetcher.ErrorWrapper<undefined>;

export type ApproveCatalogRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: ApproveCatalogRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchApproveCatalogRevision = (
  variables: ApproveCatalogRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    ApproveCatalogRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    ApproveCatalogRevisionPathParams
  >({
    url: "/api/v3/product/catalog/revisions/{revisionId}/approve",
    method: "put",
    ...variables,
    signal,
  });

export const useApproveCatalogRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      ApproveCatalogRevisionError,
      ApproveCatalogRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    ApproveCatalogRevisionError,
    ApproveCatalogRevisionVariables
  >({
    mutationFn: (variables: ApproveCatalogRevisionVariables) =>
      fetchApproveCatalogRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RejectCatalogRevisionPathParams = {
  revisionId: string;
};

export type RejectCatalogRevisionError = Fetcher.ErrorWrapper<undefined>;

export type RejectCatalogRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: RejectCatalogRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchRejectCatalogRevision = (
  variables: RejectCatalogRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    RejectCatalogRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    RejectCatalogRevisionPathParams
  >({
    url: "/api/v3/product/catalog/revisions/{revisionId}/reject",
    method: "put",
    ...variables,
    signal,
  });

export const useRejectCatalogRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      RejectCatalogRevisionError,
      RejectCatalogRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    RejectCatalogRevisionError,
    RejectCatalogRevisionVariables
  >({
    mutationFn: (variables: RejectCatalogRevisionVariables) =>
      fetchRejectCatalogRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApplyCatalogRevisionPathParams = {
  revisionId: string;
};

export type ApplyCatalogRevisionError = Fetcher.ErrorWrapper<undefined>;

export type ApplyCatalogRevisionVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: ApplyCatalogRevisionPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchApplyCatalogRevision = (
  variables: ApplyCatalogRevisionVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    ApplyCatalogRevisionError,
    Schemas.ApprovalRequest,
    {},
    {},
    ApplyCatalogRevisionPathParams
  >({
    url: "/api/v3/product/catalog/revisions/{revisionId}/apply",
    method: "put",
    ...variables,
    signal,
  });

export const useApplyCatalogRevision = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      ApplyCatalogRevisionError,
      ApplyCatalogRevisionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    ApplyCatalogRevisionError,
    ApplyCatalogRevisionVariables
  >({
    mutationFn: (variables: ApplyCatalogRevisionVariables) =>
      fetchApplyCatalogRevision({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostTemplateError = Fetcher.ErrorWrapper<undefined>;

export type PostTemplateVariables = {
  body: Schemas.NewTemplate;
} & V3ApiContext["fetcherOptions"];

export const fetchPostTemplate = (
  variables: PostTemplateVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.TemplateCreationResponse,
    PostTemplateError,
    Schemas.NewTemplate,
    {},
    {},
    {}
  >({
    url: "/api/v3/clusters/templates",
    method: "post",
    ...variables,
    signal,
  });

export const usePostTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TemplateCreationResponse,
      PostTemplateError,
      PostTemplateVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.TemplateCreationResponse,
    PostTemplateError,
    PostTemplateVariables
  >({
    mutationFn: (variables: PostTemplateVariables) =>
      fetchPostTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTemplatesQueryParams = {
  /**
   * The page number for pagination.
   *
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * @example asc
   * @default desc
   */
  order?: "asc" | "desc";
  /**
   * @example Starter,Dedicated
   */
  tags?: string;
};

export type GetTemplatesError = Fetcher.ErrorWrapper<undefined>;

export type GetTemplatesVariables = {
  queryParams?: GetTemplatesQueryParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetTemplates = (
  variables: GetTemplatesVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.TemplatesResponse,
    GetTemplatesError,
    undefined,
    {},
    GetTemplatesQueryParams,
    {}
  >({ url: "/api/v3/clusters/templates", method: "get", ...variables, signal });

export const useGetTemplates = <TData = Schemas.TemplatesResponse,>(
  variables: GetTemplatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TemplatesResponse,
      GetTemplatesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<
    Schemas.TemplatesResponse,
    GetTemplatesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v3/clusters/templates",
      operationId: "getTemplates",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTemplates({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetTemplateByIdPathParams = {
  templateId: string;
};

export type GetTemplateByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTemplateByIdVariables = {
  pathParams: GetTemplateByIdPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetTemplateById = (
  variables: GetTemplateByIdVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.Template,
    GetTemplateByIdError,
    undefined,
    {},
    {},
    GetTemplateByIdPathParams
  >({
    url: "/api/v3/clusters/templates/{templateId}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetTemplateById = <TData = Schemas.Template,>(
  variables: GetTemplateByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Template, GetTemplateByIdError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<Schemas.Template, GetTemplateByIdError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v3/clusters/templates/{templateId}",
      operationId: "getTemplateById",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTemplateById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateTemplateTagsPathParams = {
  templateId: string;
};

export type UpdateTemplateTagsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateTemplateTagsVariables = {
  body?: Schemas.Tags;
  pathParams: UpdateTemplateTagsPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchUpdateTemplateTags = (
  variables: UpdateTemplateTagsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    undefined,
    UpdateTemplateTagsError,
    Schemas.Tags,
    {},
    {},
    UpdateTemplateTagsPathParams
  >({
    url: "/api/v3/clusters/templates/{templateId}/tags",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateTemplateTags = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateTemplateTagsError,
      UpdateTemplateTagsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateTemplateTagsError,
    UpdateTemplateTagsVariables
  >({
    mutationFn: (variables: UpdateTemplateTagsVariables) =>
      fetchUpdateTemplateTags({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetRolloutsQueryParams = {
  /**
   * The page number for pagination.
   *
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * @example asc
   * @default desc
   */
  order?: "asc" | "desc";
  /**
   * @example Starter,Dedicated
   */
  tags?: string;
};

export type GetRolloutsError = Fetcher.ErrorWrapper<undefined>;

export type GetRolloutsVariables = {
  queryParams?: GetRolloutsQueryParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetRollouts = (
  variables: GetRolloutsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.Rollouts,
    GetRolloutsError,
    undefined,
    {},
    GetRolloutsQueryParams,
    {}
  >({ url: "/api/v3/rollouts", method: "get", ...variables, signal });

export const useGetRollouts = <TData = Schemas.Rollouts,>(
  variables: GetRolloutsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Rollouts, GetRolloutsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<Schemas.Rollouts, GetRolloutsError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v3/rollouts",
      operationId: "getRollouts",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRollouts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SubmitRolloutError = Fetcher.ErrorWrapper<undefined>;

export type SubmitRolloutVariables = {
  body: Schemas.RolloutSubmit;
} & V3ApiContext["fetcherOptions"];

export const fetchSubmitRollout = (
  variables: SubmitRolloutVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.RolloutResponse,
    SubmitRolloutError,
    Schemas.RolloutSubmit,
    {},
    {},
    {}
  >({ url: "/api/v3/rollouts", method: "post", ...variables, signal });

export const useSubmitRollout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RolloutResponse,
      SubmitRolloutError,
      SubmitRolloutVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.RolloutResponse,
    SubmitRolloutError,
    SubmitRolloutVariables
  >({
    mutationFn: (variables: SubmitRolloutVariables) =>
      fetchSubmitRollout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApproveRolloutPathParams = {
  rolloutId: string;
};

export type ApproveRolloutError = Fetcher.ErrorWrapper<undefined>;

export type ApproveRolloutVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: ApproveRolloutPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchApproveRollout = (
  variables: ApproveRolloutVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    ApproveRolloutError,
    Schemas.ApprovalRequest,
    {},
    {},
    ApproveRolloutPathParams
  >({
    url: "/api/v3/rollouts/{rolloutId}/approve",
    method: "put",
    ...variables,
    signal,
  });

export const useApproveRollout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      ApproveRolloutError,
      ApproveRolloutVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    ApproveRolloutError,
    ApproveRolloutVariables
  >({
    mutationFn: (variables: ApproveRolloutVariables) =>
      fetchApproveRollout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RejectRolloutPathParams = {
  rolloutId: string;
};

export type RejectRolloutError = Fetcher.ErrorWrapper<undefined>;

export type RejectRolloutVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: RejectRolloutPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchRejectRollout = (
  variables: RejectRolloutVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    RejectRolloutError,
    Schemas.ApprovalRequest,
    {},
    {},
    RejectRolloutPathParams
  >({
    url: "/api/v3/rollouts/{rolloutId}/reject",
    method: "put",
    ...variables,
    signal,
  });

export const useRejectRollout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      RejectRolloutError,
      RejectRolloutVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    RejectRolloutError,
    RejectRolloutVariables
  >({
    mutationFn: (variables: RejectRolloutVariables) =>
      fetchRejectRollout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ExecuteRolloutPathParams = {
  rolloutId: string;
};

export type ExecuteRolloutError = Fetcher.ErrorWrapper<undefined>;

export type ExecuteRolloutVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: ExecuteRolloutPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchExecuteRollout = (
  variables: ExecuteRolloutVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    ExecuteRolloutError,
    Schemas.ApprovalRequest,
    {},
    {},
    ExecuteRolloutPathParams
  >({
    url: "/api/v3/rollouts/{rolloutId}/apply",
    method: "put",
    ...variables,
    signal,
  });

export const useExecuteRollout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      ExecuteRolloutError,
      ExecuteRolloutVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    ExecuteRolloutError,
    ExecuteRolloutVariables
  >({
    mutationFn: (variables: ExecuteRolloutVariables) =>
      fetchExecuteRollout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RollbackRolloutPathParams = {
  rolloutId: string;
};

export type RollbackRolloutError = Fetcher.ErrorWrapper<undefined>;

export type RollbackRolloutVariables = {
  body: Schemas.ApprovalRequest;
  pathParams: RollbackRolloutPathParams;
} & V3ApiContext["fetcherOptions"];

export const fetchRollbackRollout = (
  variables: RollbackRolloutVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.ApprovalResponse,
    RollbackRolloutError,
    Schemas.ApprovalRequest,
    {},
    {},
    RollbackRolloutPathParams
  >({
    url: "/api/v3/rollouts/{rolloutId}/rollback",
    method: "put",
    ...variables,
    signal,
  });

export const useRollbackRollout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ApprovalResponse,
      RollbackRolloutError,
      RollbackRolloutVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV3ApiContext();
  return reactQuery.useMutation<
    Schemas.ApprovalResponse,
    RollbackRolloutError,
    RollbackRolloutVariables
  >({
    mutationFn: (variables: RollbackRolloutVariables) =>
      fetchRollbackRollout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTagsQueryParams = {
  /**
   * The page number for pagination.
   *
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * @example asc
   * @default desc
   */
  order?: "asc" | "desc";
  /**
   * @example Starter,Dedicated
   */
  tags?: string;
};

export type GetTagsError = Fetcher.ErrorWrapper<undefined>;

export type GetTagsVariables = {
  queryParams?: GetTagsQueryParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetTags = (
  variables: GetTagsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    Schemas.TagsResponse,
    GetTagsError,
    undefined,
    {},
    GetTagsQueryParams,
    {}
  >({ url: "/api/v3/tags", method: "get", ...variables, signal });

export const useGetTags = <TData = Schemas.TagsResponse,>(
  variables: GetTagsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TagsResponse, GetTagsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<Schemas.TagsResponse, GetTagsError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v3/tags",
      operationId: "getTags",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTags({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetMeteringRecordsPathParams = {
  orgId: string;
  clusterId: string;
};

export type GetMeteringRecordsQueryParams = {
  /**
   * Start date of the time range in format dd/mm/yyyy
   *
   * @format date
   * @pattern ^\\d{2}/\\d{2}/\\d{4}$
   */
  from: string;
  /**
   * End date of the time range in format dd/mm/yyyy
   *
   * @format date
   * @pattern ^\\d{2}/\\d{2}/\\d{4}$
   */
  to: string;
};

export type GetMeteringRecordsHeaders = {
  Accept:
    | "application/json"
    | "text/csv"
    | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
};

export type GetMeteringRecordsError = Fetcher.ErrorWrapper<undefined>;

export type GetMeteringRecordsVariables = {
  headers: GetMeteringRecordsHeaders;
  pathParams: GetMeteringRecordsPathParams;
  queryParams: GetMeteringRecordsQueryParams;
} & V3ApiContext["fetcherOptions"];

export const fetchGetMeteringRecords = (
  variables: GetMeteringRecordsVariables,
  signal?: AbortSignal,
) =>
  v3ApiFetch<
    string,
    GetMeteringRecordsError,
    undefined,
    GetMeteringRecordsHeaders,
    GetMeteringRecordsQueryParams,
    GetMeteringRecordsPathParams
  >({
    url: "/api/v3/orgs/{orgId}/metering/{clusterId}/records",
    method: "get",
    ...variables,
    signal,
  });

export const useGetMeteringRecords = <TData = string,>(
  variables: GetMeteringRecordsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetMeteringRecordsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV3ApiContext(options);
  return reactQuery.useQuery<string, GetMeteringRecordsError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v3/orgs/{orgId}/metering/{clusterId}/records",
      operationId: "getMeteringRecords",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMeteringRecords({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}";
      operationId: "getCluster";
      variables: GetClusterVariables;
    }
  | {
      path: "/api/v3/clusters/extensions";
      operationId: "getSupportedExtensions";
      variables: GetSupportedExtensionsVariables;
    }
  | {
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions/{revisionId}";
      operationId: "getClusterRevisionById";
      variables: GetClusterRevisionByIdVariables;
    }
  | {
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/applied";
      operationId: "getClusterAppliedRevision";
      variables: GetClusterAppliedRevisionVariables;
    }
  | {
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revisions";
      operationId: "getClusterRevisions";
      variables: GetClusterRevisionsVariables;
    }
  | {
      path: "/api/v3/orgs/{orgId}/clusters/{clusterId}/revision/{revisionId}/render";
      operationId: "getClusterRevisionRender";
      variables: GetClusterRevisionRenderVariables;
    }
  | {
      path: "/api/v3/clusters";
      operationId: "getAllClustersRevisions";
      variables: GetAllClustersRevisionsVariables;
    }
  | {
      path: "/api/v3/product/catalog";
      operationId: "getProductCatalog";
      variables: GetProductCatalogVariables;
    }
  | {
      path: "/api/v3/clusters/templates";
      operationId: "getTemplates";
      variables: GetTemplatesVariables;
    }
  | {
      path: "/api/v3/clusters/templates/{templateId}";
      operationId: "getTemplateById";
      variables: GetTemplateByIdVariables;
    }
  | {
      path: "/api/v3/rollouts";
      operationId: "getRollouts";
      variables: GetRolloutsVariables;
    }
  | {
      path: "/api/v3/tags";
      operationId: "getTags";
      variables: GetTagsVariables;
    }
  | {
      path: "/api/v3/orgs/{orgId}/metering/{clusterId}/records";
      operationId: "getMeteringRecords";
      variables: GetMeteringRecordsVariables;
    };
