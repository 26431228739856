/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.11
 */
import * as reactQuery from "@tanstack/react-query";
import { useV2ApiContext, V2ApiContext } from "./v2ApiContext";
import type * as Fetcher from "./v2ApiFetcher";
import { v2ApiFetch } from "./v2ApiFetcher";
import type * as Schemas from "./v2ApiSchemas";
import type { ClientErrorStatus, ServerErrorStatus } from "./v2ApiUtils";

export type UpdateKillSwitchError = Fetcher.ErrorWrapper<undefined>;

export type UpdateKillSwitchVariables = {
  body: Schemas.KillSwitch;
} & V2ApiContext["fetcherOptions"];

/**
 * update Kill switch
 */
export const fetchUpdateKillSwitch = (
  variables: UpdateKillSwitchVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.KillSwitch,
    UpdateKillSwitchError,
    Schemas.KillSwitch,
    {},
    {},
    {}
  >({ url: "/api/v2/killSwitch", method: "put", ...variables, signal });

/**
 * update Kill switch
 */
export const useUpdateKillSwitch = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.KillSwitch,
      UpdateKillSwitchError,
      UpdateKillSwitchVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    Schemas.KillSwitch,
    UpdateKillSwitchError,
    UpdateKillSwitchVariables
  >({
    mutationFn: (variables: UpdateKillSwitchVariables) =>
      fetchUpdateKillSwitch({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetKillSwitchStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetKillSwitchStatusVariables = V2ApiContext["fetcherOptions"];

/**
 * Get kill-switch status
 */
export const fetchGetKillSwitchStatus = (
  variables: GetKillSwitchStatusVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.KillSwitch,
    GetKillSwitchStatusError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/v2/killSwitch", method: "get", ...variables, signal });

/**
 * Get kill-switch status
 */
export const useGetKillSwitchStatus = <TData = Schemas.KillSwitch,>(
  variables: GetKillSwitchStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.KillSwitch,
      GetKillSwitchStatusError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<
    Schemas.KillSwitch,
    GetKillSwitchStatusError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v2/killSwitch",
      operationId: "getKillSwitchStatus",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetKillSwitchStatus({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteClusterPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
  /**
   * Cluster id to delete
   */
  clusterId: string;
};

export type DeleteClusterError = Fetcher.ErrorWrapper<undefined>;

export type DeleteClusterVariables = {
  pathParams: DeleteClusterPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * delete a Cluster
 */
export const fetchDeleteCluster = (
  variables: DeleteClusterVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    undefined,
    DeleteClusterError,
    undefined,
    {},
    {},
    DeleteClusterPathParams
  >({
    url: "/api/v2/orgs/{orgId}/clusters/{clusterId}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * delete a Cluster
 */
export const useDeleteCluster = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteClusterError,
      DeleteClusterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteClusterError,
    DeleteClusterVariables
  >({
    mutationFn: (variables: DeleteClusterVariables) =>
      fetchDeleteCluster({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InternalApiKeyPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
};

export type InternalApiKeyQueryParams = {
  /**
   * Encoded64 Customer ID
   */
  customerId: string;
};

export type InternalApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type InternalApiKeyVariables = {
  pathParams: InternalApiKeyPathParams;
  queryParams: InternalApiKeyQueryParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns an internal api key, to interact with the api from console
 */
export const fetchInternalApiKey = (
  variables: InternalApiKeyVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.InternalAPiKey,
    InternalApiKeyError,
    undefined,
    {},
    InternalApiKeyQueryParams,
    InternalApiKeyPathParams
  >({
    url: "/api/v2/orgs/{orgId}/apikeys/internal",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns an internal api key, to interact with the api from console
 */
export const useInternalApiKey = <TData = Schemas.InternalAPiKey,>(
  variables: InternalApiKeyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.InternalAPiKey,
      InternalApiKeyError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<
    Schemas.InternalAPiKey,
    InternalApiKeyError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v2/orgs/{orgId}/apikeys/internal",
      operationId: "internalApiKey",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchInternalApiKey({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateOrgError = Fetcher.ErrorWrapper<undefined>;

export type CreateOrgVariables = {
  body: Schemas.OrgRequest;
} & V2ApiContext["fetcherOptions"];

/**
 * create new Org
 */
export const fetchCreateOrg = (
  variables: CreateOrgVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.Organisation,
    CreateOrgError,
    Schemas.OrgRequest,
    {},
    {},
    {}
  >({ url: "/api/v2/orgs", method: "post", ...variables, signal });

/**
 * create new Org
 */
export const useCreateOrg = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Organisation,
      CreateOrgError,
      CreateOrgVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    Schemas.Organisation,
    CreateOrgError,
    CreateOrgVariables
  >({
    mutationFn: (variables: CreateOrgVariables) =>
      fetchCreateOrg({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetOrgsQueryParams = {
  /**
   * owner ID to filter by
   */
  owner_id?: string;
};

export type GetOrgsError = Fetcher.ErrorWrapper<undefined>;

export type GetOrgsResponse = Schemas.Organisation[];

export type GetOrgsVariables = {
  queryParams?: GetOrgsQueryParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns all Organisations
 */
export const fetchGetOrgs = (
  variables: GetOrgsVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    GetOrgsResponse,
    GetOrgsError,
    undefined,
    {},
    GetOrgsQueryParams,
    {}
  >({ url: "/api/v2/orgs", method: "get", ...variables, signal });

/**
 * Returns all Organisations
 */
export const useGetOrgs = <TData = GetOrgsResponse,>(
  variables: GetOrgsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetOrgsResponse, GetOrgsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<GetOrgsResponse, GetOrgsError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v2/orgs",
      operationId: "getOrgs",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetOrgs({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindOrgByIdPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
};

export type FindOrgByIdError = Fetcher.ErrorWrapper<undefined>;

export type FindOrgByIdVariables = {
  pathParams: FindOrgByIdPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns a single Organisation
 */
export const fetchFindOrgById = (
  variables: FindOrgByIdVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.Organisation,
    FindOrgByIdError,
    undefined,
    {},
    {},
    FindOrgByIdPathParams
  >({ url: "/api/v2/orgs/{orgId}", method: "get", ...variables, signal });

/**
 * Returns a single Organisation
 */
export const useFindOrgById = <TData = Schemas.Organisation,>(
  variables: FindOrgByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Organisation, FindOrgByIdError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<Schemas.Organisation, FindOrgByIdError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v2/orgs/{orgId}",
      operationId: "findOrgById",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindOrgById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteOrgPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
};

export type DeleteOrgError = Fetcher.ErrorWrapper<{
  status: Exclude<ClientErrorStatus | ServerErrorStatus, 200 | 400 | 403 | 404>;
  payload: Schemas.ErrorResponse;
}>;

export type DeleteOrgVariables = {
  pathParams: DeleteOrgPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Delete Organisation
 */
export const fetchDeleteOrg = (
  variables: DeleteOrgVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<undefined, DeleteOrgError, undefined, {}, {}, DeleteOrgPathParams>(
    { url: "/api/v2/orgs/{orgId}", method: "delete", ...variables, signal },
  );

/**
 * Delete Organisation
 */
export const useDeleteOrg = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteOrgError,
      DeleteOrgVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<undefined, DeleteOrgError, DeleteOrgVariables>({
    mutationFn: (variables: DeleteOrgVariables) =>
      fetchDeleteOrg({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindHivesQueryParams = {
  /**
   * Feature
   */
  feature?: "kafka" | "kinesis" | "jwt" | "certificate" | "customDns";
  /**
   * customer email
   */
  email?: string;
  /**
   * The page number for pagination.
   *
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * Search query that could either be email, cluster id, org id, org name or a segment of those. Full email is required for email search.
   */
  searchQuery?: string;
};

export type FindHivesError = Fetcher.ErrorWrapper<undefined>;

export type FindHivesVariables = {
  queryParams?: FindHivesQueryParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns hive details that owned by the organisation
 */
export const fetchFindHives = (
  variables: FindHivesVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.OrganizationHivesResponsePaginated,
    FindHivesError,
    undefined,
    {},
    FindHivesQueryParams,
    {}
  >({ url: "/api/v2/hives", method: "get", ...variables, signal });

/**
 * Returns hive details that owned by the organisation
 */
export const useFindHives = <
  TData = Schemas.OrganizationHivesResponsePaginated,
>(
  variables: FindHivesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationHivesResponsePaginated,
      FindHivesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationHivesResponsePaginated,
    FindHivesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v2/hives",
      operationId: "findHives",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindHives({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindHivesByOrgIdPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
};

export type FindHivesByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type FindHivesByOrgIdVariables = {
  pathParams: FindHivesByOrgIdPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns hive details that owned by the organisation
 */
export const fetchFindHivesByOrgId = (
  variables: FindHivesByOrgIdVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.OrganizationHivesResponse,
    FindHivesByOrgIdError,
    undefined,
    {},
    {},
    FindHivesByOrgIdPathParams
  >({ url: "/api/v2/orgs/{orgId}/hives", method: "get", ...variables, signal });

/**
 * Returns hive details that owned by the organisation
 */
export const useFindHivesByOrgId = <TData = Schemas.OrganizationHivesResponse,>(
  variables: FindHivesByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationHivesResponse,
      FindHivesByOrgIdError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationHivesResponse,
    FindHivesByOrgIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v2/orgs/{orgId}/hives",
      operationId: "findHivesByOrgId",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindHivesByOrgId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetOrgUsersPathParams = {
  /**
   * ID of organisation
   */
  orgId: string;
};

export type GetOrgUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetOrgUsersResponse = Schemas.User[];

export type GetOrgUsersVariables = {
  pathParams: GetOrgUsersPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns all Org Users
 */
export const fetchGetOrgUsers = (
  variables: GetOrgUsersVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    GetOrgUsersResponse,
    GetOrgUsersError,
    undefined,
    {},
    {},
    GetOrgUsersPathParams
  >({ url: "/api/v2/orgs/{orgId}/users", method: "get", ...variables, signal });

/**
 * Returns all Org Users
 */
export const useGetOrgUsers = <TData = GetOrgUsersResponse,>(
  variables: GetOrgUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetOrgUsersResponse, GetOrgUsersError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<GetOrgUsersResponse, GetOrgUsersError, TData>({
    queryKey: queryKeyFn({
      path: "/api/v2/orgs/{orgId}/users",
      operationId: "getOrgUsers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetOrgUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RemoveAdminFromOrgPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
  /**
   * User ID
   */
  userId: string;
};

export type RemoveAdminFromOrgError = Fetcher.ErrorWrapper<undefined>;

export type RemoveAdminFromOrgVariables = {
  pathParams: RemoveAdminFromOrgPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Remove Admin User from Org
 */
export const fetchRemoveAdminFromOrg = (
  variables: RemoveAdminFromOrgVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    undefined,
    RemoveAdminFromOrgError,
    undefined,
    {},
    {},
    RemoveAdminFromOrgPathParams
  >({
    url: "/api/v2/orgs/{orgId}/users/{userId}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * Remove Admin User from Org
 */
export const useRemoveAdminFromOrg = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveAdminFromOrgError,
      RemoveAdminFromOrgVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    undefined,
    RemoveAdminFromOrgError,
    RemoveAdminFromOrgVariables
  >({
    mutationFn: (variables: RemoveAdminFromOrgVariables) =>
      fetchRemoveAdminFromOrg({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OneMoreTrialPathParams = {
  /**
   * Organisation ID
   */
  orgId: string;
};

export type OneMoreTrialError = Fetcher.ErrorWrapper<undefined>;

export type OneMoreTrialVariables = {
  pathParams: OneMoreTrialPathParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Increments trials left count by 1
 */
export const fetchOneMoreTrial = (
  variables: OneMoreTrialVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    undefined,
    OneMoreTrialError,
    undefined,
    {},
    {},
    OneMoreTrialPathParams
  >({
    url: "/api/v2/orgs/{orgId}/oneMoreTrial",
    method: "put",
    ...variables,
    signal,
  });

/**
 * Increments trials left count by 1
 */
export const useOneMoreTrial = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OneMoreTrialError,
      OneMoreTrialVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    undefined,
    OneMoreTrialError,
    OneMoreTrialVariables
  >({
    mutationFn: (variables: OneMoreTrialVariables) =>
      fetchOneMoreTrial({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateUserError = Fetcher.ErrorWrapper<{
  status: Exclude<ClientErrorStatus | ServerErrorStatus, 200 | 400 | 403 | 404>;
  payload: Schemas.ErrorResponse;
}>;

export type ActivateUserRequestBody = {
  userId?: string;
};

export type ActivateUserVariables = {
  body?: ActivateUserRequestBody;
} & V2ApiContext["fetcherOptions"];

/**
 * Activates user by user id
 */
export const fetchActivateUser = (
  variables: ActivateUserVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    Schemas.ActivateUserResponse,
    ActivateUserError,
    ActivateUserRequestBody,
    {},
    {},
    {}
  >({ url: "/api/v2/users/activate", method: "post", ...variables, signal });

/**
 * Activates user by user id
 */
export const useActivateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ActivateUserResponse,
      ActivateUserError,
      ActivateUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    Schemas.ActivateUserResponse,
    ActivateUserError,
    ActivateUserVariables
  >({
    mutationFn: (variables: ActivateUserVariables) =>
      fetchActivateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResendActivationEmailError = Fetcher.ErrorWrapper<{
  status: Exclude<ClientErrorStatus | ServerErrorStatus, 200 | 400 | 403 | 404>;
  payload: Schemas.ErrorResponse;
}>;

export type ResendActivationEmailRequestBody = {
  userId?: string;
};

export type ResendActivationEmailVariables = {
  body?: ResendActivationEmailRequestBody;
} & V2ApiContext["fetcherOptions"];

/**
 * Resend activation email to user
 */
export const fetchResendActivationEmail = (
  variables: ResendActivationEmailVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    undefined,
    ResendActivationEmailError,
    ResendActivationEmailRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/v2/users/resendActivationEmail",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Resend activation email to user
 */
export const useResendActivationEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ResendActivationEmailError,
      ResendActivationEmailVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useV2ApiContext();
  return reactQuery.useMutation<
    undefined,
    ResendActivationEmailError,
    ResendActivationEmailVariables
  >({
    mutationFn: (variables: ResendActivationEmailVariables) =>
      fetchResendActivationEmail({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUsersByEmailQueryParams = {
  /**
   * Email address of the user to search for
   */
  email: string;
};

export type GetUsersByEmailError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersByEmailResponse = Schemas.UserWithOrgs[];

export type GetUsersByEmailVariables = {
  queryParams: GetUsersByEmailQueryParams;
} & V2ApiContext["fetcherOptions"];

/**
 * Returns all Users
 */
export const fetchGetUsersByEmail = (
  variables: GetUsersByEmailVariables,
  signal?: AbortSignal,
) =>
  v2ApiFetch<
    GetUsersByEmailResponse,
    GetUsersByEmailError,
    undefined,
    {},
    GetUsersByEmailQueryParams,
    {}
  >({ url: "/api/v2/users", method: "get", ...variables, signal });

/**
 * Returns all Users
 */
export const useGetUsersByEmail = <TData = GetUsersByEmailResponse,>(
  variables: GetUsersByEmailVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUsersByEmailResponse,
      GetUsersByEmailError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useV2ApiContext(options);
  return reactQuery.useQuery<
    GetUsersByEmailResponse,
    GetUsersByEmailError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/v2/users",
      operationId: "getUsersByEmail",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsersByEmail({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/api/v2/killSwitch";
      operationId: "getKillSwitchStatus";
      variables: GetKillSwitchStatusVariables;
    }
  | {
      path: "/api/v2/orgs/{orgId}/apikeys/internal";
      operationId: "internalApiKey";
      variables: InternalApiKeyVariables;
    }
  | {
      path: "/api/v2/orgs";
      operationId: "getOrgs";
      variables: GetOrgsVariables;
    }
  | {
      path: "/api/v2/orgs/{orgId}";
      operationId: "findOrgById";
      variables: FindOrgByIdVariables;
    }
  | {
      path: "/api/v2/hives";
      operationId: "findHives";
      variables: FindHivesVariables;
    }
  | {
      path: "/api/v2/orgs/{orgId}/hives";
      operationId: "findHivesByOrgId";
      variables: FindHivesByOrgIdVariables;
    }
  | {
      path: "/api/v2/orgs/{orgId}/users";
      operationId: "getOrgUsers";
      variables: GetOrgUsersVariables;
    }
  | {
      path: "/api/v2/users";
      operationId: "getUsersByEmail";
      variables: GetUsersByEmailVariables;
    };
