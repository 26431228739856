import React, { type PropsWithChildren } from 'react'

import './assets/styles/variables.css'
import './styles/index.scss'

import { ChakraProvider, defineStyle, extendTheme } from '@chakra-ui-v2/react'
import theme from '@hivemq/ui-theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Layout } from './components/templates/Layout/Layout'

import '@fontsource/raleway/400.css'
import '@fontsource/raleway/500.css'
import '@fontsource/raleway/600.css'
import '@fontsource/raleway/700.css'
import '@fontsource/raleway/800.css'
import '@fontsource/roboto'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import { isDevelop, isProduction } from '@/scripts/environments'
import { ContextProvider } from '@/stores'

const extendedTheme = extendTheme({
  ...theme,
  components: {
    ...theme.components,
    // the default style for Button has issues
    // TODO: commit these changes to ui-theme
    Button: {
      ...theme.components.Button,
      baseStyle: {
        ...theme.components.Button.baseStyle,
        boxSizing: 'border-box',
        // remove scaling to 0.9 when clicking the button
        _active: {},
      },
      variants: {
        ...theme.components.Button.variants,
        secondary: {
          ...theme.components.Button.variants.secondary,
          _active: {
            background: 'background.bg-active',
          },
        },
        positive: defineStyle({
          background: 'green.500',
          borderColor: 'green.500',
          color: 'neutrals.WHITE',
          _hover: {
            background: 'green.600',
            borderColor: 'green.600',
          },
          _active: {
            background: 'green.700',
            borderColor: 'green.700',
          },
          _disabled: {
            background: 'background.bg-inactive',
            borderColor: 'border.border-inactive',
            color: 'text.text-inactive',
          },
        }),
        link: defineStyle({
          background: 'transparent',
          border: 'none',
          color: 'blue.600',
          paddingLeft: 0,
          paddingRight: '7px',
          height: '20px',
          maxHeight: '20px',
          _hover: {
            color: 'blue.600',
          },
          _active: {
            color: 'blue.700',
          },
        }),
      },
    },
  },
})

const client = new QueryClient()

export function App({ children }: PropsWithChildren) {
  return (
    <ChakraProvider theme={extendedTheme}>
      {/* <RootErrorBoundary> */}
      <QueryClientProvider client={client}>
        <ContextProvider>
          <Layout>{children}</Layout>
        </ContextProvider>
        {!isProduction && <ReactQueryDevtools />}
      </QueryClientProvider>
      {/* </RootErrorBoundary> */}
    </ChakraProvider>
  )
}
