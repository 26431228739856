import { clusterConfigTableRoute } from '@/features/ClusterConfigs/pages/ClusterConfigTable/ClusterConfigTable'
import { identitySupportRoute } from '@/features/IdentitySupport/pages/IdentitySupport'
import { legacyHivesRoute } from '@/features/LegacyHives/pages/LegacyHivesTable'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import {
  clustersRootRoute,
  legacyHivesRootRoute,
  templatesRootRoute,
} from '@/router/rootRoutes'
import { Icon, Tag } from '@chakra-ui-v2/react'
import { Sidebar as SidebarLibrary } from '@hivemq/ui-library'
import {
  type AnyRoute,
  Link,
  useMatches,
  useNavigate,
} from '@tanstack/react-router'
import { ExternalLink } from 'lucide-react'

type SidebarItem = {
  title: string
  tag?: string
  colorScheme?: string
  /*
   * These have to be functions otherwise you will run into a chicken egg problem as within the bundling they exist
   * But in the runtime they have to be defined first.
   * The workaround is to define them as functions, which which you target the route object in the runtime.
   */
  getRoute: () => AnyRoute
  getRootRoute: () => AnyRoute
}

const options = [
  {
    title: 'Identity Support',
    getRoute: () => identitySupportRoute,
    getRootRoute: () => identitySupportRoute,
  },
  {
    title: 'Legacy Hives',
    tag: 'Old infra',
    getRoute: () => legacyHivesRoute,
    getRootRoute: () => legacyHivesRootRoute,
  },
  {
    title: 'Hives & Configs',
    tag: 'New',
    colorScheme: 'green',
    getRoute: () => clusterConfigTableRoute,
    getRootRoute: () => clustersRootRoute,
  },
  {
    title: 'Templates',
    getRoute: () => templateTableRoute,
    getRootRoute: () => templatesRootRoute,
  },
] satisfies SidebarItem[]

export function Sidebar() {
  const matches = useMatches()

  const navigate = useNavigate()

  return (
    <SidebarLibrary.Root>
      <SidebarLibrary.Group Title="">
        <SidebarLibrary.List>
          {options.map((item) => (
            <SidebarLibrary.ListItem
              as={Link}
              to={item.getRoute().to}
              key={item.getRoute().id}
              isActive={matches.some((matchRoute) => {
                return matchRoute.routeId === item.getRootRoute().id
              })}
              onClick={() => {
                navigate(item.getRoute())
              }}
              justifyContent="space-between"
              alignItems="center"
              display="flex"
              sx={{
                _hover: {
                  backgroundColor: 'gray.100',
                },
              }}
            >
              {item.title}

              {item.tag && (
                <Tag size="sm" colorScheme={item.colorScheme} mr={2}>
                  {item.tag}
                </Tag>
              )}
            </SidebarLibrary.ListItem>
          ))}

          <SidebarLibrary.ListItem
            as="a"
            href="https://docs.google.com/document/d/1z-snSivljxA-yn9F5eF5s7Ypi2MAmN7HR5IQU1oQh7Q/edit?tab=t.0"
            target="blank"
            rel="noreferrer noopener"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            sx={{
              _hover: {
                backgroundColor: 'gray.100',
              },
            }}
          >
            Documentation
            <Icon display="flex" as={ExternalLink} w={4} h={4} mr={2} />
          </SidebarLibrary.ListItem>
        </SidebarLibrary.List>
      </SidebarLibrary.Group>
    </SidebarLibrary.Root>
  )
}
