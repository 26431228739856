import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { ReactEditor } from '@/components/Editor/HmqcEditor'
import SearchMenu from '@/components/SearchMenu/SearchMenu'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import {
  useGetCluster,
  useGetTemplates,
  usePutCluster,
} from '@/generated/http-clients/v3ApiComponents'
import type {
  ClusterHead,
  TemplateDetail,
} from '@/generated/http-clients/v3ApiSchemas'
import type { DeepNonNullable } from '@/helpers/clusterTypes'
import {
  type DeploymentLabelOption,
  deploymentLabels,
} from '@/helpers/deploymentLabels'
import { router } from '@/router'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Spinner,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui-v2/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { Select } from 'chakra-react-select'
import { CheckIcon, ChevronDown } from 'lucide-react'
import * as React from 'react'
import { useRef, useState } from 'react'
import YAML from 'yaml'
import { useHandleKeyDown } from '../../utils/handleKeyDown'
import { clusterConfigTableRoute } from '../ClusterConfigTable/ClusterConfigTable'
import { clusterConfigsRootRoute } from '../ClusterConfigsPage/ClusterConfigsPage'

export const clusterConfigDraftRoute = createRoute({
  getParentRoute: () => clusterConfigsRootRoute,
  path: '$baseRevisionId/draft',
  component: () => <ClusterConfigDraft />,
})

const editorOptions = {
  language: 'yaml',
  automaticLayout: true,
  scrollBeyondLastLine: false,
  tabSize: 4,
  insertSpaces: true,
}

type TemplateState = {
  page: number
  data: TemplateDetail[]
  hasNext: boolean
}

type State = {
  system: string
  override: string
  customer: string
  comment: string
  templateId: string
  readyToSubmit: boolean
  deploymentLabel: DeploymentLabelOption
}

export function ClusterConfigDraft() {
  const pathParams = clusterConfigDraftRoute.useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const cluster = useGetCluster({ pathParams })

  const popoverTextareaRef = useRef(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const [changedState, setChangedState] = useState<State>({
    system: '',
    override: '',
    customer: '',
    templateId: '',
    comment: '',
    readyToSubmit: false,
    deploymentLabel: deploymentLabels[0],
  })

  React.useEffect(() => {
    if (!cluster.isSuccess || !cluster.data) {
      return
    }

    const {
      system = {},
      override,
      customer,
      template_id: templateId = '',
    } = cluster.data
    const { deployment_label: deploymentLabelData, ...restSystemGlobal } =
      system?.global || {}

    const deploymentLabel =
      deploymentLabels.find(({ value }) => value === deploymentLabelData) ??
      deploymentLabels[0]

    setChangedState({
      system: YAML.stringify({
        system: {
          global: { ...restSystemGlobal },
        },
      }),
      override: YAML.stringify({
        override,
      }),
      customer: YAML.stringify({
        customer,
      }),
      templateId,
      comment: '',
      readyToSubmit: false,
      deploymentLabel,
    })
  }, [cluster.isSuccess, cluster.data])

  const createClusterConfigRevision = usePutCluster()

  const updateComment = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const updatedComment = event.target.value
    setChangedState((previousState) => {
      return {
        ...previousState,
        comment: updatedComment,
        readyToSubmit: updatedComment.trim().length > 0,
      }
    })
  }

  function submitNewRevision() {
    if (!changedState.readyToSubmit) {
      return
    }

    try {
      const {
        system: systemData,
        override,
        customer,
        templateId,
        comment,
        deploymentLabel,
      } = changedState

      const system = YAML.parse(systemData)
        ?.system as DeepNonNullable<ClusterHead>['system']
      system.global = {
        ...system.global,
        deployment_label: deploymentLabel.value,
      }

      const yamlConfig = `${YAML.stringify({ system })}${override}${customer}template_id: ${templateId}\n`

      const jsonConfig = {
        ...YAML.parse(yamlConfig),
        changelog: comment,
      }

      createClusterConfigRevision
        .mutateAsync({
          body: jsonConfig,
          pathParams,
        })
        .then(() => {
          toast({
            title: 'Created revision successfully',
            status: 'success',
            duration: 2000,
          })
          return navigate({
            to: clusterConfigsRootRoute.to,
            params: pathParams,
          })
        })
        .catch((error) => {
          console.error('error posting', error)
          toast({
            title: 'Failed to submit the new revision',
            status: 'error',
            duration: 5000,
          })
        })
    } catch (error) {
      console.error('Failed to parse YAML', error)
      // TODO: look at integrating a YAML validator to display errors. A schema would need to be provided by the backend via an API
      toast({
        title: 'This configuration cannot be submitted as the YAML is invalid.',
        status: 'error',
        duration: 5000,
      })
    }
  }

  const breadCrumbs = [
    { name: 'Hives & Configs', path: clusterConfigTableRoute.to },
    {
      name: pathParams.clusterId,
      path: router.buildLocation({
        to: clusterConfigsRootRoute.to,
        params: pathParams,
      }).href,
    },
    { name: 'New Cluster Revision', path: '' },
  ]

  const [templatesState, setTemplatesState] = useState<TemplateState>({
    page: 1,
    data: [],
    hasNext: false,
  })

  const { data: templatesData, isLoading: templatesIsLoading } =
    useGetTemplates({
      queryParams: { page: templatesState.page, order: 'desc' },
    })

  function onChange<K extends keyof State>(name: K, value: State[K]) {
    setChangedState((previousState) => {
      return {
        ...previousState,
        [name]: value,
      }
    })
  }

  React.useEffect(() => {
    if (templatesData?.templates) {
      setTemplatesState((state) => ({
        ...state,
        hasNext: templatesData.has_next_page ?? false,
        data: [...state.data, ...templatesData.templates],
      }))
    }
  }, [templatesData])

  return (
    <PageLayout
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
      title="New cluster revision"
      action={
        <Popover placement="bottom-end" initialFocusRef={popoverTextareaRef}>
          <PopoverTrigger>
            {!cluster.isError && !cluster.isLoading ? (
              <Button
                variant="primary"
                rightIcon={<Icon as={ChevronDown} mr={-1} />}
              >
                Review changes
              </Button>
            ) : (
              <></>
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold" border={0}>
              Submit your draft
              <PopoverCloseButton top={2} />
            </PopoverHeader>
            <PopoverBody>
              <Textarea
                placeholder="Leave a comment"
                value={changedState.comment}
                onChange={updateComment}
                onKeyDown={useHandleKeyDown(submitButtonRef)} // Add keydown listener
                ref={popoverTextareaRef}
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="end" border={0}>
              <Button
                variant="primary"
                leftIcon={<Icon as={CheckIcon} ml={-1} />}
                onClick={submitNewRevision}
                ref={submitButtonRef}
                isDisabled={!changedState.readyToSubmit}
              >
                Submit
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      }
    >
      {cluster.isLoading ? <Skeleton h="400px" w="100%" /> : null}

      {cluster.isError && !cluster.isFetching ? (
        <ApiErrorState
          apiResource={cluster as unknown as UseQueryResult}
          errorTitle="An error happened"
          errorDescription="We failed to load the data from the server for the cluster revision"
        />
      ) : null}

      {cluster.isSuccess && cluster.data !== null ? (
        <VStack width="100%" alignItems="stretch" gap={6}>
          <FormControl isRequired display="flex" alignItems="center">
            <FormLabel w={36}>Base Template</FormLabel>
            <SearchMenu
              data={(templatesState.data || []).map((data) => ({
                value: data.template_id,
                tag: data.name,
              }))}
              defaultValue={changedState.templateId}
              title="Please select a Base Template"
              loading={templatesIsLoading}
              morePages={templatesState.hasNext}
              onLoadMore={() =>
                setTemplatesState((state) => ({
                  ...state,
                  page: state.page + 1,
                }))
              }
              onChange={(value) => onChange('templateId', value)}
            />
          </FormControl>

          <FormControl isRequired display="flex" alignItems="center">
            <FormLabel w={36}>Deployment Label</FormLabel>
            <Select<DeploymentLabelOption>
              options={deploymentLabels}
              name="deploymentLabel"
              value={changedState.deploymentLabel}
              placeholder="Please select a deployment label"
              onChange={(value) =>
                onChange('deploymentLabel', value as DeploymentLabelOption)
              }
            />
          </FormControl>

          <VStack alignItems="stretch" gap={2}>
            <Heading as="h2" variant="h3">
              System
            </Heading>
            <ReactEditor
              language="yaml"
              loading={<Spinner my={8} />}
              value={changedState.system}
              options={{
                ...editorOptions,
                readOnly: false,
              }}
              onChange={(newData) => {
                if (!newData) {
                  return
                }

                setChangedState((previousState) => {
                  return {
                    ...previousState,
                    system: newData,
                  }
                })
              }}
            />
          </VStack>

          <VStack alignItems="stretch" gap={2}>
            <Heading as="h2" variant="h3">
              Overrides
            </Heading>
            <ReactEditor
              language="yaml"
              loading={<Spinner my={8} />}
              value={changedState.override}
              options={editorOptions}
              onChange={(newData) => {
                if (!newData) {
                  return
                }

                setChangedState((previousState) => {
                  return {
                    ...previousState,
                    override: newData,
                  }
                })
              }}
            />
          </VStack>

          <VStack alignItems="stretch" gap={2}>
            <Heading as="h2" variant="h3">
              Customer
            </Heading>
            <ReactEditor
              language="yaml"
              loading={<Spinner my={8} />}
              value={changedState.customer}
              options={editorOptions}
              onChange={(newData) => {
                if (!newData) {
                  return
                }

                setChangedState((previousState) => {
                  return {
                    ...previousState,
                    customer: newData,
                  }
                })
              }}
            />
          </VStack>
        </VStack>
      ) : null}
    </PageLayout>
  )
}
