import { Box } from '@chakra-ui-v2/react'
import { Header } from '@hivemq/ui-library'
import { useOktaAuth } from '@okta/okta-react'
import { Link } from '@tanstack/react-router'
import { UserIcon } from 'lucide-react'

export function NavigationBar() {
  const { oktaAuth } = useOktaAuth()

  return (
    <Header.Root>
      {/* Toggle can be added later on */}
      {/* <Header.SidebarToggle /> */}
      <Header.Logo
        as={Link}
        to="/"
        src="/logo-bee-bw.svg"
        alt="HiveMQ Cloud Logo"
        title="Cloud Backoffice"
        h="55px"
        pl={4}
        sx={{
          img: {
            padding: '2px',
            height: '56px',
          },
          'span:first-of-type': {
            fontWeight: 400,
          },
        }}
        _hover={{ backgroundColor: 'transparent' }}
      />
      <Header.Divider />

      <Box flexGrow="2" textAlign="right" pr={6}>
        <Header.Menu overlayId="profile">
          <Header.MenuButton icon={UserIcon} aria-label="Profile" />
          <Header.MenuContent>
            <Header.MenuContentDetails textAlign="center">
              <b>Hello 👋</b>
              <br />
              Welcome back!
            </Header.MenuContentDetails>
            <Header.MenuContentItem
              onClick={() =>
                oktaAuth.signOut({
                  clearTokensBeforeRedirect: true,
                  postLogoutRedirectUri: window.location.origin,
                })
              }
              sx={{
                _hover: {
                  backgroundColor: 'red.600',
                },
              }}
            >
              Logout
            </Header.MenuContentItem>
          </Header.MenuContent>
        </Header.Menu>
      </Box>
    </Header.Root>
  )
}
