import EmptyStateImage from '@/assets/imgs/api-error.svg'
import { DataTag } from '@/components/DataTag/DataTag'
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui-v2/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { RefreshCwIcon } from 'lucide-react'
import type React from 'react'

type APIEmptyStateProps = {
  apiResource: UseQueryResult
  errorTitle?: React.ReactNode
  errorDescription?: React.ReactNode
  buttonText?: string
  showApiErrorContext?: boolean
}

export function ApiErrorState({
  apiResource,
  errorTitle = 'An Error Occurred!',
  errorDescription,
  buttonText = 'Reload',
  // @RobinAtherton - I created a flag for this component to turn
  // on more dev-like error messages that users
  // can copy paste and send into threads to
  // make it easier for us to debug stuff
  showApiErrorContext = true,
}: APIEmptyStateProps) {
  return (
    <>
      {apiResource.error ? (
        <Box
          border="2px solid"
          borderColor="red.400"
          borderRadius="6"
          padding="8"
          w="100%"
          backgroundColor="surface.0"
        >
          <Flex justifyContent="center" gap="8" alignItems="center">
            <Image src={EmptyStateImage} alt="Empty state image" />
            <VStack gap="4" alignItems="start" mt={3}>
              <Box>
                <Text fontWeight={500} fontSize="1rem" mb={0}>
                  {errorTitle}
                </Text>
                {errorDescription ? (
                  <Text mb={0}>{errorDescription}</Text>
                ) : null}
              </Box>
              {/*  Show API Call Specifics for debugging,
                   should be okay to do that here as the
                   backoffice is an internal tool
                   */}
              {showApiErrorContext ? (
                <VStack align="start" border="2px" borderRadius="4" p={2}>
                  <Text fontWeight={500}>DEV INFORMATION</Text>
                  <Text>{apiResource.error.message}</Text>
                  {/*  Making sure that the values are strings even if they are more complex objects so,
                  we can make sure that we get all the information from the user with 1 button press */}
                  {apiResource.error.cause ? (
                    <>
                      <Text mb={0}>Error Cause</Text>
                      <DataTag
                        hasCopy
                        value={apiResource.error.cause.toString()}
                      >
                        {apiResource.error.cause.toString()}
                      </DataTag>
                    </>
                  ) : null}
                  {apiResource.error.stack ? (
                    <>
                      <Text mb={0}>Error Stack</Text>
                      <DataTag
                        hasCopy
                        value={JSON.stringify(apiResource.error.stack)}
                      >
                        {JSON.stringify(apiResource.error.stack)}
                      </DataTag>
                    </>
                  ) : null}
                </VStack>
              ) : null}
              <Button
                variant="secondary"
                isLoading={apiResource.isLoading}
                loadingText="Loading..."
                leftIcon={<Icon as={RefreshCwIcon} />}
                onClick={() => apiResource.refetch()}
              >
                {buttonText}
              </Button>
            </VStack>
          </Flex>
        </Box>
      ) : null}
    </>
  )
}
