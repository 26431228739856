import { DataInput } from '@/components/DataInput/DataInput'
import { SectionCard } from '@/components/SectionCard/SectionCard'
import { FormLabel, VStack } from '@chakra-ui-v2/react'
import * as React from 'react'

type Props = {
  name?: string
  description?: string
  url?: string
  target_revision?: string
  path?: string
  templateId?: string
}

export function TemplateData({
  name,
  description,
  url,
  target_revision,
  path,
  templateId = '',
}: Props) {
  return (
    <SectionCard title={`Template ${templateId} Details`}>
      <VStack alignItems="start">
        <FormLabel fontWeight={600} fontSize={18}>
          General
        </FormLabel>
        <DataInput label="Name" value={name} />
        <DataInput label="Description" value={description} />
        <FormLabel mt={4} fontWeight={600} fontSize={18}>
          Chart Info
        </FormLabel>
        <DataInput label="Repository URL" value={url} />
        <DataInput mt={1} label="Target Revision" value={target_revision} />
        <DataInput mt={1} label="Chart Path" value={path} />
      </VStack>
    </SectionCard>
  )
}
