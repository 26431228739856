import { Editor, type Monaco } from '@monaco-editor/react'
import * as monaco from 'monaco-editor'
import React, { useState, useEffect, useRef } from 'react'

type EditorWrapperProps = {
  yamlContent?: string
  readOnly?: boolean
  onChange?: (value: string) => void // Callback for content changes
  minHeight?: number // The minimum height in px the editor will take up
}

type MonacoEditorType = monaco.editor.IStandaloneCodeEditor

export function EditorWrapper({
  yamlContent = '',
  readOnly = false,
  minHeight = 400,
  onChange,
}: EditorWrapperProps) {
  const [content, setContent] = useState<string | undefined>(yamlContent)
  const [height, setHeight] = useState(minHeight)
  const editorRef = useRef<MonacoEditorType | null>(null)
  const monacoRef = React.useRef<Monaco | null>(null)

  // Define a custom theme
  useEffect(() => {
    monaco.editor.defineTheme('customLightYamlTheme', {
      base: 'vs',
      inherit: true,
      rules: [
        { token: 'string.yaml', foreground: '008000' },
        { token: 'key.yaml', foreground: '2b91af' },
      ],
      colors: {
        'editor.background': '#ffffff',
        'editor.foreground': '#000000',
        'editor.lineHighlightBackground': '#f4f4f4',
        'editorWidget.border': '#cccccc',
        'editorWidget.background': '#ffffff',
      },
    })
  }, [])

  function handleEditorDidMount(editor: MonacoEditorType, monaco: Monaco) {
    editorRef.current = editor
    monacoRef.current = monaco
    updateEditorHeight()
  }

  function updateEditorHeight() {
    if (editorRef.current && monacoRef.current) {
      const lineHeight = editorRef.current.getOption(
        monacoRef.current.editor.EditorOption.lineHeight,
      )
      const lineCount = editorRef.current.getModel()?.getLineCount() || 0
      const calculatedHeight = lineHeight * lineCount + 20 // Adds padding
      setHeight(Math.max(calculatedHeight, minHeight)) // Ensure minimum height
    }
  }

  const handleEditorChange = (value: string | undefined) => {
    if (!readOnly && value && onChange) {
      setContent(value)
      onChange(value)
      updateEditorHeight()
    }
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        minHeight: `${minHeight}px`, // Ensure min height in the container
        height: `${height}px`,
      }}
    >
      <Editor
        theme="customLightYamlTheme"
        language="yaml"
        options={{
          scrollbar: {
            vertical: 'hidden',
            horizontal: 'hidden',
            alwaysConsumeMouseWheel: false,
          },
          minimap: { enabled: false },
          overviewRulerLanes: 0,
          folding: true,
          scrollBeyondLastColumn: 0,
          automaticLayout: true,
          scrollBeyondLastLine: false,
          readOnly: readOnly,
          formatOnPaste: true,
          insertSpaces: true,
          wordWrap: 'on',
          fontSize: 16,
          fontFamily: 'monospace',
          tabSize: 2,
          lineNumbers: 'on',
          padding: { top: 16, bottom: 16 },
        }}
        height={height}
        value={readOnly ? yamlContent : content}
        onMount={handleEditorDidMount}
        onChange={handleEditorChange}
        loading={<div>Loading editor...</div>}
      />
    </div>
  )
}
