import { SectionCard } from '@/components/SectionCard/SectionCard'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { EditorWrapper } from '@/features/Templates/components/EditorWrapper'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import {
  useGetTags,
  usePostTemplate,
} from '@/generated/http-clients/v3ApiComponents'
import type { NewTemplate } from '@/generated/http-clients/v3ApiSchemas'
import { templatesRootRoute } from '@/router/rootRoutes'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
  useToast,
} from '@chakra-ui-v2/react'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { CreatableSelect } from 'chakra-react-select'
import type React from 'react'
import { useState } from 'react'
import { z } from 'zod'

export const templateCreateRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: 'create',
  component: () => <TemplateCreate />,
})
const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  chart_source: z.object({
    chart_path: z.string().min(1, 'Chart Path is required'),
    chart_repo_url: z
      .string()
      .min(1, 'Repository URL is required.')
      .url('Repository URL must be a valid URL'),
    chart_target_revision: z.string().min(1, 'Target Revision is required'),
  }),
  tags: z.array(z.string()).optional(),
  yaml: z.string().min(1, 'YAML content is required'),
})

export function TemplateCreate() {
  const [formValues, setFormValues] = useState<NewTemplate>({
    name: '',
    description: '',
    tags: [],
    chart_source: {
      chart_path: '',
      chart_repo_url: '',
      chart_target_revision: '',
    },
    yaml: '',
  })

  const [errors, setErrors] = useState<Record<string, string>>({})
  const createTemplate = usePostTemplate()
  const tags = useGetTags({})
  const navigate = useNavigate()

  const toast = useToast()
  function submitCreateTemplate() {
    // Validate form values
    const validationResult = formSchema.safeParse(formValues)

    if (!validationResult.success) {
      const validationErrors: Record<string, string> = {}
      // biome-ignore  lint/complexity/noForEach: @RobinAtherton .forEach is fine here
      validationResult.error.errors.forEach((err) => {
        if (err.path.length) {
          let key = err.path.join('.')
          // @RobinAtherton - Remove "chart_source." prefix for its children, this makes it alot easier to map the errors.
          if (key.startsWith('chart_source.')) {
            key = key.replace('chart_source.', '')
          }
          validationErrors[key] = err.message
        }
      })
      setErrors(validationErrors)
      return
    }

    setErrors({})

    createTemplate
      .mutateAsync({
        body: formValues,
      })
      .then(() => {
        toast({
          title: 'Request to create template was sent successfully.',
          status: 'success',
          duration: 2000,
        })
        return navigate({ to: templateTableRoute.to })
      })
      .catch((error) => {
        console.error('error creating template', error)
        toast({
          title: 'Failed to create template.',
          status: 'error',
          duration: 5000,
        })
      })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFormValues((prevValues) => {
      if (name in prevValues.chart_source) {
        return {
          ...prevValues,
          chart_source: {
            ...prevValues.chart_source,
            [name]: value,
          },
        }
      }
      return {
        ...prevValues,
        [name]: value,
      }
    })
  }

  const handleChangeYaml = (yaml: string | undefined) => {
    if (yaml) {
      setFormValues((prevValues) => ({
        ...prevValues,
        yaml: yaml,
      }))
    }
  }

  return (
    <PageLayout
      title="Create Template"
      action={
        <Button variant="primary" onClick={submitCreateTemplate}>
          Submit template
        </Button>
      }
    >
      <SectionCard title="Add template information">
        <VStack alignItems="start" gap={6}>
          <FormControl isRequired isInvalid={Boolean(errors.name)}>
            <FormLabel>Name</FormLabel>
            <Input
              id="name"
              name="name"
              placeholder="Enter a name for your template"
              value={formValues.name}
              onChange={handleInputChange}
            />
            {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>

          <FormControl>
            <FormLabel display="inline-flex">
              Description&nbsp;
              <Text fontWeight={400} color="text.text-subtle">
                (Optional)
              </Text>
            </FormLabel>
            <Input
              id="description"
              name="description"
              placeholder="Enter a description for your template"
              value={formValues.description}
              onChange={handleInputChange}
            />
            {errors.description ? (
              <Text color="red.500">{errors.description}</Text>
            ) : null}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.chart_repo_url)}>
            <FormLabel>Repository URL</FormLabel>
            <Input
              id="chart_repo_url"
              name="chart_repo_url"
              placeholder="Enter the chart repository"
              value={formValues.chart_source.chart_repo_url}
              onChange={handleInputChange}
            />
            {errors.chart_repo_url && (
              <FormErrorMessage>{errors.chart_repo_url}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isRequired
            isInvalid={Boolean(errors.chart_target_revision)}
          >
            <FormLabel>Target Revision</FormLabel>
            <Input
              id="chart_target_revision"
              name="chart_target_revision"
              placeholder="Enter the chart target revision"
              value={formValues.chart_source.chart_target_revision}
              onChange={handleInputChange}
            />
            {errors.chart_target_revision && (
              <FormErrorMessage>
                {errors.chart_target_revision}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.chart_path)}>
            <FormLabel>Chart Path</FormLabel>
            <Input
              id="chart_path"
              name="chart_path"
              placeholder="Enter the chart path"
              value={formValues.chart_source.chart_path}
              onChange={handleInputChange}
            />
            {errors.chart_path && (
              <FormErrorMessage>{errors.chart_path}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel display="inline-flex">
              Tags&nbsp;
              <Text fontWeight={400} color="text.text-subtle">
                (Optional)
              </Text>
            </FormLabel>
            <CreatableSelect
              value={(formValues.tags || []).map((tag) => ({
                label: tag,
                value: tag,
              }))}
              onChange={(newValue) => {
                const updatedTags = newValue
                  ? newValue.map((option) => option.value)
                  : []
                setFormValues((prev) => ({
                  ...prev,
                  tags: updatedTags,
                }))
              }}
              // For some reason it doesn't recognize this data structure
              options={(tags.data?.tags || []).map((tag) => ({
                label: tag,
                value: tag,
              }))}
              isClearable
              isMulti
              menuPortalTarget={document.body}
              placeholder="Create or select tags"
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  marginTop: 0,
                  zIndex: 9999,
                }),
              }}
            />
          </FormControl>
        </VStack>
      </SectionCard>
      <SectionCard title="Add template YAML">
        <FormControl isInvalid={Boolean(errors.yaml)}>
          {errors.yaml && (
            <FormErrorMessage mb={2}>{errors.yaml}</FormErrorMessage>
          )}
          <EditorWrapper onChange={handleChangeYaml} />
        </FormControl>
      </SectionCard>
    </PageLayout>
  )
}
