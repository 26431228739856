import { ReactEditor } from '@/components/Editor/HmqcEditor'
import { Heading, Spinner, VStack } from '@chakra-ui-v2/react'
import React from 'react'

type EditableSectionProps = {
  title: string
  value: string
  onChange: (newValue: string) => void
  editorOptions?: object
  isReadOnly?: boolean
}

export function EditableSection({
  title,
  value,
  onChange,
  editorOptions = {},
  isReadOnly = false,
}: EditableSectionProps) {
  return (
    <VStack alignItems="stretch" gap={2}>
      <Heading as="h2" variant="h3">
        {title}
      </Heading>
      <ReactEditor
        language="yaml"
        loading={<Spinner my={8} />}
        value={value}
        options={{
          ...editorOptions,
          readOnly: isReadOnly,
        }}
        onChange={(newData) => {
          if (!newData) {
            return
          }
          onChange(newData)
        }}
      />
    </VStack>
  )
}
