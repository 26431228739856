import { Icon, Link, type LinkProps } from '@chakra-ui-v2/react'
import { ExternalLinkIcon } from 'lucide-react'
import React, { type PropsWithChildren } from 'react'

type ExternalLinkProps = PropsWithChildren<Omit<LinkProps, 'isExternal'>>

export function ExternalLink({ children, ...props }: ExternalLinkProps) {
  return (
    <Link
      {...props}
      color="blue.500"
      display="flex"
      alignItems="center"
      gap={0.5}
      isExternal
    >
      {children}
      <Icon
        as={ExternalLinkIcon}
        w={3}
        h={3}
        ml={0.5}
        display="inline-block"
        data-testid="external-link-icon"
      />
    </Link>
  )
}
