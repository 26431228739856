import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { DataInput } from '@/components/DataInput/DataInput'
import { SectionCard } from '@/components/SectionCard/SectionCard'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { EditorWrapper } from '@/features/Templates/components/EditorWrapper'
import { templateTableRoute } from '@/features/Templates/page/TemplateTable'
import { useGetTemplateById } from '@/generated/http-clients/v3ApiComponents'
import { templatesRootRoute } from '@/router/rootRoutes'
import { FormLabel, Skeleton, VStack } from '@chakra-ui-v2/react'
import type { UseQueryResult } from '@tanstack/react-query'
import { createRoute } from '@tanstack/react-router'
import React from 'react'

export const templateDetailsRoute = createRoute({
  getParentRoute: () => templatesRootRoute,
  path: '$templateId',
  component: () => <TemplateDetails />,
})

export function TemplateDetails() {
  const params = templateDetailsRoute.useParams()
  const templateId = params.templateId

  if (!templateId) {
    return null
  }

  const template = useGetTemplateById({
    pathParams: {
      templateId: templateId,
    },
  })

  const title = `Template #${templateId}`
  const breadCrumbs = [
    { name: 'Templates', path: templateTableRoute.to },
    { name: title, path: '' },
  ]

  return (
    <PageLayout
      title={title}
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
      action={<div />}
    >
      {template.isLoading ? <Skeleton h="500px" w="100%" /> : null}
      {template.isError ? (
        <ApiErrorState
          showApiErrorContext
          apiResource={template as unknown as UseQueryResult}
          errorTitle="An error happened"
          errorDescription="We failed to load the data from the server for the templates."
        />
      ) : null}
      {template.isSuccess ? (
        <>
          <SectionCard title="Details">
            <VStack alignItems="start">
              <FormLabel fontWeight={600} fontSize={18}>
                General
              </FormLabel>
              <DataInput label="Name" value={template.data?.name} />
              <DataInput
                label="Description"
                value={template.data?.description}
              />
              <FormLabel mt={4} fontWeight={600} fontSize={18}>
                Chart Info
              </FormLabel>
              <DataInput
                label="Repository URL"
                value={template.data?.chart_source.chart_repo_url}
              />
              <DataInput
                mt={1}
                label="Target Revision"
                value={template.data?.chart_source.chart_target_revision}
              />
              <DataInput
                mt={1}
                label="Chart Path"
                value={template.data?.chart_source.chart_path}
              />
            </VStack>
          </SectionCard>
          <SectionCard title="YAML">
            {/*  @ts-ignore template yaml is always there or empty */}
            <EditorWrapper readOnly={true} yamlContent={template.data?.yaml} />
          </SectionCard>
        </>
      ) : null}
    </PageLayout>
  )
}
