import type { KafkaExtensionConfiguration } from '@/generated/http-clients/v3ApiSchemas'
import { applicationRootRoute } from '@/router/rootRoutes'
import { Box, FormControl, FormLabel, Switch } from '@chakra-ui-v2/react'
import {
  ConfigFormManager,
  type FormModel,
  buildFormModel,
  convertJsonToXml,
  convertToXMLDocument,
  fetchXMLDocument,
  serializeDocument,
} from '@hivemq/xsd-forms'
import { createRoute } from '@tanstack/react-router'
import { useEffect, useMemo, useState } from 'react'
import style from './KafkaExtensionPage.module.css'
import { GENERIC_KAFKA_XML, calculateUiSchemaGenericKafka } from './kafkaUtils'

export const kafkaExtensionRoute = createRoute({
  getParentRoute: () => applicationRootRoute,
  path: '/kafka-confluent',
  component: KafkaExtensionPage,
})

type Json = boolean | number | string | null | { [key: string]: Json } | Json[]

type KafkaExtensionPageProps = {
  initialConfig: KafkaExtensionConfiguration | undefined
  onSubmit: (json?: KafkaExtensionConfiguration) => void
}

export function KafkaExtensionPage({
  initialConfig,
  onSubmit,
}: KafkaExtensionPageProps) {
  const [expertModeActive, setExpertModeActive] = useState(false)

  const [formModel, setFormModel] = useState<FormModel | null>(null)

  const [jsonValues, setJsonValues] = useState<Json>({})

  const xsd = useMemo(() => {
    if (expertModeActive) {
      return fetchXMLDocument(
        'https://hivemq-cloud.github.io/xsd-serving/serve/4.25.0/xsd/hivemq-kafka-extension.xsd',
      )
    }
    return fetchXMLDocument('/xsd/generic-kafka-starter/xsd/schema.xsd')
  }, [expertModeActive])

  function assignFormModel(xml: string) {
    buildFormModel(
      '',
      xsd,
      convertToXMLDocument(xml),
      calculateUiSchemaGenericKafka(jsonValues),
    ).then((data) => {
      setFormModel(data)
    })
  }

  // reload the form model every time the base XML or the expert mode changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: only runs when the resource is fetched
  useEffect(() => {
    // if an initial config is passed, transform it to XML and load it
    if (initialConfig) {
      const jsonInXml = convertJsonToXml(initialConfig)
      assignFormModel(jsonInXml)
      return
    }

    // otherwise load the empty Kafka XML
    assignFormModel(GENERIC_KAFKA_XML)
  }, [GENERIC_KAFKA_XML, expertModeActive, initialConfig])

  return (
    <>
      {formModel && (
        <Box
          p={4}
          backgroundColor="background.bg-base"
          border="1px solid"
          borderColor="border.border-base"
          borderRadius={6}
        >
          <FormControl
            display="flex"
            alignItems="center"
            gap={2}
            w="auto"
            pb={4}
            isDisabled
          >
            <Switch
              id="expert-mode"
              isChecked={expertModeActive}
              onChange={() => setExpertModeActive(!expertModeActive)}
            />
            <FormLabel htmlFor="expert-mode" mb="0" fontWeight="400">
              Expert mode
            </FormLabel>
          </FormControl>
          <div className={style.kafkaConfiguration}>
            <ConfigFormManager
              key={`config_manager_${formModel.name}`}
              xsd={formModel.xsd.rootElement}
              title={formModel.title}
              keyRefs={formModel.xsd.keyRefs}
              uiSchema={calculateUiSchemaGenericKafka(jsonValues)}
              xml={serializeDocument(formModel.xml)}
              onSubmit={(_xml, _files, json) => {
                onSubmit(json)
              }}
              onChange={(_xml, json) => {
                setJsonValues(json)
              }}
              submitButtonText="Save Configuration"
            />
          </div>
        </Box>
      )}
    </>
  )
}
