import { DataTag } from '@/components/DataTag/DataTag'
import { useGetAllClustersRevisions } from '@/generated/http-clients/v3ApiComponents'
import type { LatestClusterRevision } from '@/generated/http-clients/v3ApiSchemas'
import { timeAgo } from '@/helpers/timeFormatter'
import { clustersRootRoute } from '@/router/rootRoutes'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui-v2/react'
import { Link, createRoute, useNavigate } from '@tanstack/react-router'
import capitalize from 'lodash/capitalize'
import { Plus, Unplug } from 'lucide-react'
import { useState } from 'react'
import * as React from 'react'

import { ApiErrorState } from '@/components/ApiErrorState/ApiErrorState'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { ClusterConfigCombinedStates } from '@/features/ClusterConfigs/components/States/ClusterConfigCombinedStates'
import { legacyHivesOrganizationDetailsRoute } from '@/features/LegacyHives/pages/LegacyHivesOrganizationDetails'
import type { UseQueryResult } from '@tanstack/react-query'
import revisionStates from '../../utils/revisionStates'
import { clusterConfigsRootRoute } from '../ClusterConfigsPage/ClusterConfigsPage'
import styles from './clusterConfigsTable.module.css'

export const clusterConfigTableRoute = createRoute({
  getParentRoute: () => clustersRootRoute,
  path: '/',
  component: () => <ClusterConfigTable />,
})

export function ClusterConfigTable() {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const clusters = useGetAllClustersRevisions(
    {
      queryParams: { page, order: 'desc' },
    },
    {
      refetchInterval: 20000, // 20s
    },
  )

  function handleNextPage() {
    if (clusters.data?.has_next_page) {
      setPage((prev) => prev + 1)
    }
  }

  function handlePreviousPage() {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  function hiveNameClassName(revision: LatestClusterRevision) {
    if (revisionStates.isDisabled(revision)) {
      return styles.hiveNameDisabled
    }
    return ''
  }

  function revisionActionMetadata(latestRevision: LatestClusterRevision) {
    const { revision } = latestRevision
    let time = revision.rollout?.updated_at
      ? timeAgo(revision.rollout?.updated_at || '')
      : undefined
    const by = `by ${
      revisionStates.isSubmitted(latestRevision)
        ? revision.rollout?.created_by
        : revision.rollout?.approved_by
    }`
    // only in the submitted case: if updated_at is undefined but created_at is, use that field
    if (
      revisionStates.isSubmitted(latestRevision) &&
      !time &&
      revision.rollout?.created_at
    ) {
      time = timeAgo(revision.rollout?.created_at)
    }

    return `${time ? `${time} ` : ''}${by}`
  }

  return (
    <PageLayout
      title="Hives & Configs"
      action={
        <Link to="/clusters/new">
          <Button variant="primary" leftIcon={<Icon as={Plus} ml={-1} />}>
            New config
          </Button>
        </Link>
      }
    >
      {clusters.isLoading ? <Skeleton h="500px" w="100%" /> : null}
      {clusters.isError || !clusters.data ? (
        <ApiErrorState
          apiResource={clusters as unknown as UseQueryResult}
          errorTitle="An error happened"
          errorDescription="We failed to load the data from the server for the cluster configs."
        />
      ) : null}
      {clusters.isSuccess ? (
        <>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width="32px" paddingRight="0" />
                <Th pl={4}>Name</Th>
                <Th>Org ID</Th>
                <Th>Hive ID</Th>
                <Th>Deployment Label</Th>
              </Tr>
            </Thead>
            <Tbody>
              {clusters?.data?.revisions?.map((latestRevision) => {
                const { revision } = latestRevision
                return (
                  <Tr
                    key={revision.hive_id}
                    className={styles.trAlignContentTop}
                    _hover={{ backgroundColor: 'gray.100' }}
                  >
                    <Td
                      data-testid="combined-state"
                      className={styles.trAlignContentTopTdIcon}
                    >
                      <ClusterConfigCombinedStates revision={latestRevision} />
                    </Td>
                    <Td data-testid="hive-name">
                      <Flex direction="column" gap="1">
                        <HStack justifyContent="space-between">
                          <Link
                            to={clusterConfigsRootRoute.to}
                            params={{
                              orgId: revision.org_id,
                              clusterId: revision.hive_id,
                            }}
                            style={{ width: 'fit-content' }}
                          >
                            <Text
                              variant="P2"
                              fontWeight={700}
                              whiteSpace="nowrap"
                              maxWidth={230}
                              textOverflow="ellipsis"
                              overflow="hidden"
                              className={hiveNameClassName(latestRevision)}
                              _hover={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                            >
                              {revision.hive_name}
                            </Text>
                          </Link>
                          {latestRevision.ignore_for_reconcile ? (
                            <Tag size="md" colorScheme="red" gap={2}>
                              <Icon as={Unplug} />
                              Sync disabled
                            </Tag>
                          ) : null}
                        </HStack>
                        <Text fontSize="sm" color="gray">
                          {capitalize(revision.approval_state.state)}&nbsp;
                          {revisionActionMetadata(latestRevision)}
                        </Text>
                      </Flex>
                    </Td>
                    <Td data-testid="org-id" width={100}>
                      <DataTag
                        fontWeight={400}
                        value={revision.org_id}
                        hasCopy={true}
                        onClickBody={() =>
                          navigate({
                            to: legacyHivesOrganizationDetailsRoute.to,
                            params: {
                              orgId: revision.org_id,
                            },
                          })
                        }
                      >
                        {revision.org_id}
                      </DataTag>
                    </Td>
                    <Td data-testid="hive-id" width={100}>
                      <DataTag
                        fontWeight={400}
                        value={revision.hive_id}
                        hasCopy={true}
                        maxWidthPx={200}
                      >
                        {revision.hive_id}
                      </DataTag>
                    </Td>
                    <Td data-testid="deployment-label" width={265}>
                      <DataTag
                        fontWeight={400}
                        value={revision.deployment_label}
                        hasCopy={true}
                        maxWidthPx={200}
                      >
                        {revision.deployment_label}
                      </DataTag>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={4}
            width="100%"
          >
            <Button onClick={handlePreviousPage} isDisabled={page === 1}>
              Previous
            </Button>
            <Box alignContent="center">
              Page {clusters.data?.current_page} of {clusters.data?.total_pages}
            </Box>
            <Button
              onClick={handleNextPage}
              isDisabled={!clusters.data?.has_next_page}
            >
              Next
            </Button>
          </Box>
        </>
      ) : null}
      {/* TODO Oli: Filters will go here */}
    </PageLayout>
  )
}
