import type { ApprovalState } from '@/generated/http-clients/v3ApiSchemas'
import { Box, Stack, Text, Tooltip } from '@chakra-ui-v2/react'
import {
  CircleAlert,
  CircleCheck,
  FileCheck,
  FilePlus,
  FileX,
} from 'lucide-react'
import React, { type PropsWithChildren } from 'react'

type RevisionStateProps = {
  approvalState: ApprovalState
  size?: 'sm' | 'md' // default size: 'sm' (1rem)
}

type Props = {
  approvalState: ApprovalState
  hoverText?: string
}

function HoverText({ approvalState, hoverText }: Props) {
  return (
    <Stack gap={0}>
      <Text color="white" fontWeight="bold" textTransform="capitalize">
        {hoverText ?? approvalState.state}
      </Text>
      <Text color="gray" fontSize="xs">
        Approval state: {approvalState.state}
      </Text>
    </Stack>
  )
}

export function RevisionState({
  /**
   * The revision's approval state.
   */
  approvalState,
  size,
}: PropsWithChildren<RevisionStateProps>) {
  const iconSize = size === 'md' ? '1.5rem' : '1rem'

  function renderContent() {
    if (approvalState.state === 'SUBMITTED') {
      return (
        <Tooltip label={<HoverText approvalState={approvalState} />}>
          <FilePlus size={iconSize} color="gray" />
        </Tooltip>
      )
    }

    if (approvalState.state === 'APPROVED') {
      return (
        <Tooltip label={<HoverText approvalState={approvalState} />}>
          <FileCheck size={iconSize} color="green" />
        </Tooltip>
      )
    }

    if (approvalState.state === 'REJECTED') {
      return (
        <Tooltip label={<HoverText approvalState={approvalState} />}>
          <FileX size={iconSize} color="red" />
        </Tooltip>
      )
    }

    if (approvalState.state === 'APPLIED') {
      return (
        <Tooltip label={<HoverText approvalState={approvalState} />}>
          <CircleCheck size={iconSize} color="green" />
        </Tooltip>
      )
    }

    return (
      <Tooltip
        label={<HoverText approvalState={approvalState} hoverText="Unknown" />}
      >
        <CircleAlert size={iconSize} color="orange" />
      </Tooltip>
    )
  }

  return <Box paddingY="0.25rem">{renderContent()}</Box>
}
