import { Editor, type EditorProps, type Monaco } from '@monaco-editor/react'
import type { editor } from 'monaco-editor'
import * as React from 'react'

type HmqcEditor = editor.IStandaloneCodeEditor

export function ReactEditor(props: EditorProps) {
  const editorRef = React.useRef<HmqcEditor | null>(null)
  const monacoRef = React.useRef<Monaco | null>(null)
  const [height, setHeight] = React.useState(0)

  function handleEditorDidMount(editor: HmqcEditor, monaco: Monaco) {
    editorRef.current = editor
    monacoRef.current = monaco
    updateEditorHeight()
  }

  function updateEditorHeight() {
    if (editorRef.current && monacoRef.current) {
      const lineHeight = editorRef.current.getOption(
        monacoRef.current.editor.EditorOption.lineHeight,
      )
      const lineCount = editorRef.current.getModel()?.getLineCount()
      if (lineCount) {
        const newHeight = lineHeight * lineCount + 20 // Adds padding
        editorRef.current.layout()
        setHeight(Math.max(newHeight, 100))
      }
    }
  }

  React.useEffect(() => {
    updateEditorHeight()
  })

  return (
    <div
      style={{
        border: '2px solid var(--color-border-base)',
        backgroundColor: 'var(--chakra-colors-background-bg-main-container)',
        borderRadius: '10px',
        padding: '0.5rem',
      }}
    >
      <Editor
        height={height}
        {...props}
        onMount={handleEditorDidMount}
        options={{
          scrollbar: {
            vertical: 'hidden',
            horizontal: 'hidden',
            alwaysConsumeMouseWheel: false,
          },
          minimap: { enabled: false },
          overviewRulerLanes: 0,
          folding: true,
          scrollBeyondLastColumn: 0,
          automaticLayout: true,
          scrollBeyondLastLine: false,
          fontSize: 16,
          ...props.options,
        }}
      />
    </div>
  )
}
