export function useHandleKeyDown(
  buttonRef: React.RefObject<HTMLButtonElement>,
) {
  return (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if Command (Mac) or Ctrl (Windows) + Enter is pressed
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault() // Prevent the default Enter behavior (next line)

      if (buttonRef.current) {
        buttonRef.current.focus() // Move focus to the submit button

        // Add a small delay to show the active state
        setTimeout(() => {
          buttonRef.current?.click() // Simulate a click on the submit button
        }, 200)
      }
    }
  }
}
