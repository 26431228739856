import { BreadCrumbs } from '@/components/BreadCrumbs/BreadCrumbs'
import { ReactDiffEditor } from '@/components/DiffEditor/DiffEditor'
import { PageLayout } from '@/components/templates/PageLayout/PageLayout'
import { useGetClusterRevisionRender } from '@/generated/http-clients/v3ApiComponents'
import { router } from '@/router'
import { Box, Flex, Heading } from '@chakra-ui-v2/react'
import { createRoute } from '@tanstack/react-router'
import * as React from 'react'
import { clusterConfigTableRoute } from '../../pages/ClusterConfigTable/ClusterConfigTable'
import { clusterConfigsRootRoute } from '../../pages/ClusterConfigsPage/ClusterConfigsPage'

export const configRevisionComparison = createRoute({
  getParentRoute: () => clusterConfigsRootRoute,
  path: 'compare/$revisionId0/$revisionId1',
  component: () => <DynamicComparison />,
})

export function DynamicComparison() {
  const { revisionId0, revisionId1, orgId, clusterId } =
    configRevisionComparison.useParams()
  const renderedRevision0 = useGetClusterRevisionRender({
    pathParams: { revisionId: revisionId0, orgId, clusterId },
  })
  const renderedRevision1 = useGetClusterRevisionRender({
    pathParams: { revisionId: revisionId1, orgId, clusterId },
  })
  const pathParams = configRevisionComparison.useParams()

  const breadCrumbs = [
    { name: 'Hives & Configs', path: clusterConfigTableRoute.to },
    {
      name: pathParams.clusterId,
      path: router.buildLocation({
        to: clusterConfigsRootRoute.to,
        params: pathParams,
      }).href,
    },
    { name: 'Compare Cluster Revisions', path: '' },
  ]

  return (
    <PageLayout
      title="Compare Cluster Revisions"
      beforeTitle={<BreadCrumbs paths={breadCrumbs} />}
    >
      <Box as="article" w="100%" mt="6" gap="4">
        <Flex justifyContent="space-between" mb="4">
          <Box w="50%">
            <Heading as="h2" variant="h2">
              &nbsp;
              {`Revision #${revisionId0}`}&nbsp;
            </Heading>
          </Box>
          <Box w="50%">
            <Heading as="h2" variant="h2">
              &nbsp;
              {`Revision #${revisionId1}`}&nbsp;
            </Heading>
          </Box>
        </Flex>
        <ReactDiffEditor
          original={renderedRevision0.data?.yaml}
          modified={renderedRevision1.data?.yaml}
          language="yaml"
        />
      </Box>
    </PageLayout>
  )
}
