import { isDevelop, isProduction, isStaging } from '@/scripts/environments'

export function getStripeCustomerLink(stripeId: string) {
  return `https://dashboard.stripe.com/customers/${stripeId}`
}

export function getAuth0UserLink(userId: string) {
  return `https://manage.auth0.com/dashboard/eu/hivemq-cloud/users/${userId}`
}

/**
 * Legacy function mirroring the behaviour from the old constants.ts file
 */
export function _getGrafanaLinkForOrgId() {
  return 'https://grafana.hmqc.dev/d/1a_R8_r4k/hive-dashboard?orgId=1'
}

export function getGrafanaLinkForOrgId(orgId: string) {
  return `https://grafana.hmqc.dev/d/1a_R8_r4k/hive-dashboard?orgId=${orgId}`
}

type Apiary = 'a01' | 'a02' | 'a03' | 's01'

export function getGrafanaLinkForCluster(apiary: string, clusterId: string) {
  return `https://grafana.hmqc.dev/d/ML74c76mb/hivemq-cluster-v2?orgId=1&var-datasource=${getGrafanaDatasource(
    apiary as Apiary,
  )}&var-diskdevice=nvme1n1&var-deployment=${getGrafanaDeployment(
    apiary as Apiary,
  )}&var-job=${clusterId}&var-namespace=${clusterId}`
}

/**
 * Fetch a grafana link for the number of sessions of a cluster.
 *
 * @param clusterId the clusterId of the cluster you wish to see the data for.
 */
export function getGrafanaLinkNumberOfSessions(clusterId: string) {
  return `https://grafana.hmqc.dev/explore?orgId=1&panes=%7B%229ZI%22:%7B%22datasource%22:%22PFC17286A489AD829%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22sum%20by%28namespace%29%20%28com_hivemq_sessions_overall_current%7Bnamespace%3D%5C%22${clusterId}%5C%22%7D%29%20%2F%202%22,%22range%22:true,%22datasource%22:%7B%22type%22:%22prometheus%22,%22uid%22:%22PFC17286A489AD829%22%7D,%22editorMode%22:%22builder%22,%22instant%22:false,%22exemplar%22:false,%22format%22:%22time_series%22,%22legendFormat%22:%22__auto%22,%22useBackend%22:false,%22disableTextWrap%22:false,%22fullMetaSearch%22:false,%22includeNullMetadata%22:true%7D%5D,%22range%22:%7B%22from%22:%22now-60d%22,%22to%22:%22now%22%7D%7D%7D&schemaVersion=1`
}

/**
 * Fetch a grafana link for the number of normalized messages of a cluster.
 *
 * @param clusterId the clusterId of the cluster you wish to see the data for.
 */
export function getGrafanaLinkNormalizedMessages(clusterId: string) {
  return `https://grafana.hmqc.dev/explore?orgId=1&panes=%7B%22rjT%22:%7B%22datasource%22:%22PFC17286A489AD829%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22sum%20by%28namespace%29%20%28com_hivemq_cloud_normalized_messages_total%7Bnamespace%3D%5C%22${clusterId}%5C%22%7D%29%22,%22range%22:true,%22datasource%22:%7B%22type%22:%22prometheus%22,%22uid%22:%22PFC17286A489AD829%22%7D,%22editorMode%22:%22builder%22,%22instant%22:false,%22exemplar%22:false,%22format%22:%22time_series%22,%22legendFormat%22:%22__auto%22%7D%5D,%22range%22:%7B%22from%22:%22now-60d%22,%22to%22:%22now%22%7D%7D%7D&schemaVersion=1`
}

export function getArgoLinkForCluster(apiary: string, clusterId: string) {
  const region = apiary === 'a01' ? 'euc1' : 'usw2'

  return `https://argocd.${apiary}.${region}.aws.hivemq.cloud/applications/apiary-hives/${clusterId}`
}

/**
 * These functions are there to determine the grafana deployment and datasource.
 * Currently some of these are still wrong for some of the values as we need to determine whether they are on a02 staging or production.
 */

/**
 * @author RobinAtherton
 * These variables are the corresponding ID's that are used by grafana for the different Deployment in the query.
 * Please add more if you see more cases that need to be covered.
 */

type ApiaryData = Record<Apiary, string>

const grafanaProdDeploymentLinks: ApiaryData = {
  a01: 'apiary-a01-prod-eu-central-1',
  a02: 'apiary-a02-prod-us-west-2',
  a03: 'apiary-a03-prod-eu-central-1',
  s01: 'unknown',
}

const grafanaStagingDeploymentLinks: ApiaryData = {
  a02: 'unknown',
  a01: 'unknown',
  a03: 'apiary-a02-staging-us-west-2',
  s01: 'unknown',
}

/**
 * Method to get the deployment for the corresponding hive.
 *
 * @param apiary the apiary of the hive you are looking at. E.g. "a01", "a02", "s01"
 */
function getGrafanaDeployment(apiary: Apiary) {
  if (isProduction) {
    return grafanaProdDeploymentLinks[apiary]
  }
  if (isStaging || isDevelop) {
    return grafanaStagingDeploymentLinks[apiary]
  }
}

/**
 * @author RobinAtherton
 * These variables are the corresponding ID's that are used by grafana for the different Datasources in the query.
 * Please add more if you see more cases that need to be covered.
 */
const grafanaProdDatasourceLinks: ApiaryData = {
  a01: 'PE016382F9B8F1892',
  a02: 'P411A647D92EFB0D1',
  a03: 'PC8CE61D1BFCAEB85',
  s01: 'unknown',
}

const grafanaStagingDatasourceLinks: ApiaryData = {
  a01: 'unknown',
  a02: 'P8D5105A906F584E5',
  a03: 'unknown',
  s01: 'P9AF0E4179ECC18FF',
}

const defaultGrafanaDataSource = 'PFC17286A489AD829'

/**
 * Method to get the datasource ID for the corresponding hive.
 *
 * @param apiary the apiary of the hive you are looking at. E.g. "a01", "a02", "s01"
 */
function getGrafanaDatasource(apiary: Apiary) {
  if (isProduction) {
    if (!grafanaProdDatasourceLinks) {
      return defaultGrafanaDataSource
    }
    return grafanaProdDatasourceLinks[apiary]
  }
  if (isStaging || isDevelop) {
    if (!grafanaStagingDatasourceLinks) {
      return defaultGrafanaDataSource
    }
    return grafanaStagingDatasourceLinks[apiary]
  }
}
