import SearchMenu from '@/components/SearchMenu/SearchMenu'
import { useClusterConfigRequest } from '@/contexts/useConfigCreateRequest'
import { useConfigData } from '@/contexts/useConfigData'
import type { TemplateDetail } from '@/generated/http-clients/v3ApiSchemas'
import {
  type DeploymentLabelOption,
  deploymentLabels,
} from '@/helpers/deploymentLabels'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  MenuItem,
  Tag,
  Text,
  VStack,
} from '@chakra-ui-v2/react'
import {
  type GroupBase,
  Select,
  type SelectComponentsConfig,
  chakraComponents,
} from 'chakra-react-select'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { z } from 'zod'

type ValueLabelOption = {
  value: string
  label: string
}

type ClusterConfigRequest = {
  system: string
  override: string
  customer: string
  templateId: string
  deploymentLabel: DeploymentLabelOption
  organizationId: string
  clusterName: string
}

type SchemaKeys = Omit<ClusterConfigRequest, 'system' | 'override' | 'customer'>
type RequestSchema = {
  [K in keyof SchemaKeys]: z.ZodType<ClusterConfigRequest[K]>
}

type TemplateState = {
  page: number
  data: TemplateDetail[]
  hasNext: boolean
}

const formSchema = z.object({
  clusterName: z
    .string()
    .regex(
      /^[a-z][a-z0-9-]+[a-z0-9]$/,
      'Cluster name must start with a letter, end with a letter or number, and contain only lowercase letters, numbers, and hyphens.',
    )
    .min(5, 'Cluster name must be at least 5 characters long.')
    .max(15, 'Cluster name must be at most 15 characters long.')
    .min(1, 'Cluster name is required.'),
  organizationId: z.string().min(1, 'Organization ID is required.'),
  templateId: z.string().min(1, 'Template ID is required.'),
  deploymentLabel: z
    .object({ label: z.string(), value: z.string() })
    .required(),
} as unknown as RequestSchema)

export const NewClusterStep1System = forwardRef((_, ref) => {
  const [clusterNameHasBeenFocused, setClusterNameHasBeenFocused] =
    useState(false)

  const { request, onChange, errors, setErrors } = useClusterConfigRequest()
  const { templatesData, templatesIsLoading, orgsData, orgsIsLoading } =
    useConfigData()

  const [templatesState, setTemplatesState] = useState<TemplateState>({
    page: 1,
    data: [],
    hasNext: false,
  })

  React.useEffect(() => {
    if (templatesData?.templates) {
      setTemplatesState((state) => ({
        ...state,
        hasNext: templatesData.has_next_page ?? false,
        data: [...state.data, ...templatesData.templates],
      }))
    }
  }, [templatesData])

  const currentTemplateIdAndLabel = React.useMemo(() => {
    const foundTemplate = (templatesState.data || []).find(
      ({ template_id }) => template_id === request.templateId,
    )
    if (!foundTemplate) {
      return
    }

    return {
      value: foundTemplate.template_id,
      label: foundTemplate.name || foundTemplate.template_id,
    }
  }, [templatesState.data, request.templateId])

  const currentOrgIdAndTag = React.useMemo(() => {
    const foundOrg = (orgsData || []).find(
      ({ org_id }) => org_id === request.organizationId,
    )
    if (!foundOrg) {
      return
    }

    return {
      value: foundOrg.org_id,
      tag: foundOrg.org_name,
    }
  }, [orgsData, request.organizationId])

  const customComponents: SelectComponentsConfig<
    ValueLabelOption,
    false,
    GroupBase<ValueLabelOption>
  > = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        <MenuItem gap={2} pl={0} backgroundColor="transparent">
          <Tag width="auto">
            <Text variant="M2">{props.data.value}</Text>
          </Tag>
          <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {props.data.label}
          </Text>
        </MenuItem>
      </chakraComponents.Option>
    ),
    SingleValue: ({ children, ...props }) => (
      <chakraComponents.SingleValue {...props}>
        <HStack gap={2} pl={0} ml={0}>
          <Tag width="auto">
            <Text variant="M2">{props.data.value}</Text>
          </Tag>
          {children}
          <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {props.data.label}
          </Text>
        </HStack>
      </chakraComponents.SingleValue>
    ),
  }

  // validate one field
  function validateField<K extends keyof SchemaKeys>(fieldName: K) {
    const validationResult = formSchema.shape[fieldName].safeParse(
      request[fieldName],
    )

    if (validationResult.success) {
      setErrors({
        ...errors,
        [fieldName]: '',
      })
      return
    }

    setErrors({
      ...errors,
      [fieldName]: validationResult.error.errors[0].message,
    })
  }

  function validateForm() {
    // Validate form values
    const validationResult = formSchema.safeParse(request)

    if (!validationResult.success) {
      const validationErrors: Record<string, string> = {}
      // biome-ignore  lint/complexity/noForEach: @RobinAtherton .forEach is fine here
      validationResult.error.errors.forEach((err) => {
        if (err.path.length) {
          const key = err.path.join('.')
          validationErrors[key] = err.message
        }
      })
      setErrors(validationErrors)
      return false
    }

    setErrors({})
    return true
  }

  // Expose validateForm to the parent
  useImperativeHandle(ref, () => ({
    validateForm,
  }))

  const handleClusterNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    onChange('clusterName', value.trim())
  }

  function handleClusterNameFocus() {
    setClusterNameHasBeenFocused(true)
  }

  function handleClusterNameBlur() {
    if (clusterNameHasBeenFocused) {
      validateField('clusterName')
      setClusterNameHasBeenFocused(false)
    }
  }

  return (
    <VStack data-testid="step-system" gap={6}>
      <FormControl isRequired isInvalid={Boolean(errors.clusterName)}>
        <FormLabel w={36}>Name</FormLabel>
        <Input
          id="name"
          name="name"
          placeholder="Enter a name for your cluster"
          value={request.clusterName}
          onChange={handleClusterNameChange}
          isInvalid={Boolean(errors.clusterName)}
          onBlur={handleClusterNameBlur}
          onFocus={handleClusterNameFocus}
        />
        {errors.clusterName ? (
          <FormErrorMessage>
            <Text>{errors.clusterName}</Text>
          </FormErrorMessage>
        ) : (
          <FormHelperText>
            Cluster name must be at least 5 characters long and use only
            lowercase letters.
          </FormHelperText>
        )}
      </FormControl>

      <FormControl isRequired isInvalid={Boolean(errors.organizationId)}>
        <FormLabel w={36}>Organization ID</FormLabel>
        <SearchMenu
          data={(orgsData || []).map((data) => ({
            value: data.org_id,
            tag: data.org_name,
          }))}
          defaultValue={request.organizationId}
          title="Please select an Organization ID"
          loading={orgsIsLoading}
          morePages={false}
          onChange={(value) => onChange('organizationId', value)}
          isInvalid={Boolean(errors.organizationId)}
        />
        {errors.organizationId && (
          <FormErrorMessage>{errors.organizationId}</FormErrorMessage>
        )}
        {/* <Container p={0} m={0}>
            {!orgsIsLoading && (
              <Select<ValueLabelOption>
                options={(orgsData || []).map((data) => ({
                  value: data.org_id,
                  label: data.org_name,
                }))}
                placeholder="Please select an Organization ID"
                components={customComponents}
                onChange={({ value }) =>
                  onChange('organizationId', value)
                }
              />
            )}
          </Container> */}
      </FormControl>

      <FormControl isRequired isInvalid={Boolean(errors.templateId)}>
        <FormLabel w={36}>Base Template</FormLabel>
        <Box backgroundColor="background.bg-base" borderRadius="md">
          <Select<ValueLabelOption>
            options={(templatesState.data || []).map((data) => ({
              value: data.template_id,
              label: data.name || data.template_id,
            }))}
            value={currentTemplateIdAndLabel}
            placeholder="Please select a Base Template"
            components={customComponents}
            onChange={(valueLabel) =>
              onChange('templateId', valueLabel?.value || '')
            }
            onMenuScrollToBottom={() => {
              // when scrolling to the bottom of the list, load more data if there is more to load
              if (templatesState.hasNext) {
                setTemplatesState((state) => ({
                  ...state,
                  page: state.page + 1,
                }))
              }
            }}
          />
        </Box>
        {errors.templateId && (
          <FormErrorMessage>{errors.templateId}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isRequired isInvalid={Boolean(errors.deploymentLabel)}>
        <FormLabel w={36}>Deployment Label</FormLabel>
        <Box backgroundColor="background.bg-base" borderRadius="md">
          <Select<DeploymentLabelOption>
            options={deploymentLabels}
            value={request.deploymentLabel}
            placeholder="Please select a Deployment Label"
            onChange={(value) =>
              onChange('deploymentLabel', value as DeploymentLabelOption)
            }
          />
        </Box>
        {errors.deploymentLabel && (
          <FormErrorMessage>{errors.deploymentLabel}</FormErrorMessage>
        )}
      </FormControl>

      {/* No need to show the free form version anymore */}
      {/* <FormControl>
          <FormLabel w={36}>Free Form</FormLabel>
          <EditorWrapper
            yamlContent={request.system}
            onChange={(value) => onChange('system', value)}
          />
        </FormControl> */}
    </VStack>
  )
})
